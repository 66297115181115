<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData"  ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="收货方式：" prop="pickup_way">
                   <el-select v-model="formData.pickup_way" placeholder="请选择" @change="handleChange"
                               style="width: 100%;">
                     <el-option v-for="item in way_list" :key="item.id" :label="item.name" :value="item.id" >
                     </el-option>
                   </el-select>
                </el-form-item>
				<el-form-item label="花材数量：" prop="product_count">
				    <el-input v-model="formData.product_count"></el-input>
				</el-form-item>
				<el-form-item label="打包费：" prop="packaging_fee" >
                   <el-select v-model="formData.packaging_fee" placeholder="请选择或输入用户需要支付的打包费" @change="handleChange"
                               style="width: 80%;" filterable allow-create>
                     <el-option v-for="item in fee_list" :key="item.id" :label="item.fee" :value="item.fee" >
                     </el-option>
                   </el-select>
                </el-form-item>
				
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { UsersUsersAdd, UsersUsersEdit,updateRuWeiApi } from "@/api/api";
import { loadPackaginFeeListApi,updateOrdersPackagingFeeApi } from "@/api/orders";
import LyDialog from "@/components/dialog/dialog";

export default {
    name: "updatePickupWayDialog",
    components: { LyDialog },
    emits: ['closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
          
			
            formData: {
			   id:0,
               pickup_way:0,
			   product_count:0,
			   packaging_fee:0
            },
			select_packaging_fee:0,
            rules: {
                // lock_count: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
               
            },
			way_list:[{name:"代理配送",id:1},{name:"快递直发用户",id:2}],
			fee_list:[],
            rolelist: [],
            options: [],
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item) {
            this.loadingTitle = "订单发货方式修改--订单号："+item.order_id
            this.dialogVisible = true
            if (item) {
                
                this.formData.id = item.id
				this.formData.pickup_way=item.pickup_way
				this.formData.product_count=item.total_product_count
				this.loadData(item.total_product_count)
            } 
        },
		handleChange(e){
			console.log("change:",e)
		},
		loadData(product_count){
			loadPackaginFeeListApi({product_count:product_count}).then(res=>{
				if(res.code!=2000){
					this.$message.error(res.msg)
				}else{
					this.fee_list=res.data.data_list
					this.formData.packaging_fee=res.data.packaging_fee
					
				}
			})
		},
        submitData() {
			if(this.formData.pickup_way==0){
				this.$message.warning("如要修改为直接向用户发货请重新选择！")
				return
			}
			let msg="确定修改该订单的收货方式为直发给用户吗？用户将支付代理费："+this.formData.packaging_fee+"元,修改成功后用户需要在10分钟内支付才能生效，否则将按下单时的发货规则发货！请及时通知用户"
			this.$confirm(msg,"提示",{
				confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
			}).then(()=>{
				this.$refs['rulesForm'].validate(obj => {
				    if (obj) {
				        this.loadingSave = true
				        let param = {
				            ...this.formData
				        }
				        if (this.formData.id) {
				            updateOrdersPackagingFeeApi(param).then(res => {
				                this.loadingSave = false
				                if (res.code == 2000) {
									this.$emit('refreshData')
				                    this.$message.success(res.msg)
				                    this.dialogVisible = false;
				                    this.handleClose()
				                    
				                } else {
				                    this.$message.warning(res.msg)
				                }
				            })
				        } 
				
				    }
				})
			}).catch(()=>{
				return
			})
			
           
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

