import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
 
} from "./request";
import { url } from "./url";
//快递物流相关的接口
export const loadExpressListApi = (params) => ajaxGet({ url: `express/list/`, params });

export const ExpressAddApi = (params) => ajaxPost({ url: `express/add/`, params });

export const ExpressEditApi = (params) => ajaxPost({ url: `express/edit/`, params });

export const manageExpressStatusApi = (params) => ajaxPost({ url: `express/manage/status`, params });

export const newDeliveryRuleFeeApi = (params) => ajaxPost({ url: `mall/orders/new/delivery-fee`, params });

export const exprotOrderExpressApi = (params) => ajaxGet({ url: `mall/export/orders-express/info`, params });


export const loadExpressBoxListApi = (params) => ajaxGet({ url: `express-box/list`, params });
export const expressBoxAddApi = (params) => ajaxPost({ url: `express-box/add`, params });
export const expressBoxEditApi = (params) => ajaxPost({ url: `express-box/edit`, params });
export const expressBoxManageApi = (params) => ajaxPost({ url: `express-box/manage/status`, params });