<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="名称：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60" clearable placeholder="手机号" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="服务地区：">
                    <el-input v-model.trim="formInline.cityname" maxlength="60" clearable placeholder="开通地区"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select size="default" v-model="formInline.is_active" placeholder="请选择" clearable style="width: 90px"
                        @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                       >查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handelAddClick" type="primary" icon="Plus"
                       >新增</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="100" align="center" label="序号" prop="id">
                   <!-- <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template> -->
                </el-table-column>
                <el-table-column min-width="50" prop="avatar" label="头像">
                    <template #default="scope">
                        <img :src="scope.row.avatar ? scope.row.avatar : defaultImg" style="width: 30px;height: 30px"
                            :onerror="defaultImg">
                    </template>
                </el-table-column>
                <!-- <el-table-column min-width="100" prop="phone" label="账号"></el-table-column> -->
                <el-table-column min-width="120"  label="名称">
					<template #default="scope">
						<el-text >{{scope.row.name}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <!-- <span v-if="scope.row.is_super" style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;">超代</span> -->
					</template>
				</el-table-column>
                <el-table-column width="150" prop="phone" label="手机号"></el-table-column>
                <el-table-column width="200"  label="开通地区"  show-overflow-tooltip>
					<template #default="scope">
						<el-text >{{scope.row.area}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <span  style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;" @click="lookAgentArea(scope.row)">详细</span>
					</template>
				</el-table-column>
				<el-table-column width="150"  label="超级代理">
					<template #default="scope">
						<span v-if="scope.row.is_super" style="color: red;">是</span>
						<span v-if="!scope.row.is_super" >否</span>
					    <span v-if="!scope.row.is_super" class="table-operate-btn " style="border: 1px solid #ccc; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'open')"
					       >开通</span>
						<span v-if="scope.row.is_super && scope.row.super_agent.status" class="table-operate-btn" style="border: 1px solid #ff5500; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'stop')"
					       >停用</span>
						<span v-if="scope.row.is_super && !scope.row.super_agent.status" class="table-operate-btn" style="border: 1px solid #ff5500; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'start')"
					       >启用</span>
						<span v-if="scope.row.is_super">{{scope.row.super_agent.appid}}</span>
					</template>
				</el-table-column> 
				<el-table-column width="100" prop="admin_name" label="归属员工"></el-table-column>
				<el-table-column width="120" label="关联运营">
					<template #default="scope">
						<span>{{scope.row.salesman_name}}</span>
						<span style="margin-left: 4px;" class="table-operate-btn" @click="handleEdit(scope.row, 'salesman')"
						    v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
					</template>
				</el-table-column>
				<el-table-column width="160" label="直发用户/收入比例" >
					<template #default="scope">
						<el-tag v-if="scope.row.can_self_pickup" @click="updateSendType(scope.row,'can')" >允许</el-tag>
						<el-tag v-if="!scope.row.can_self_pickup" @click="updateSendType(scope.row,'no')" >不允许</el-tag>
						<span style="margin-left: 4px;" >{{scope.row.self_pickup_income_ratio}}%</span>
					</template>
				</el-table-column>
				<!-- <el-table-column width="100" prop="self_pickup_income_ratio" label="直发收入比例(%)" show-overflow-tooltip="花材直发用户时代理的收入计算比例"></el-table-column> -->
                <el-table-column width="120"  label="重置密码">
					<template #default="scope">
					    <span class="table-operate-btn" @click="handleEdit(scope.row, 'updatePwd')"
					        v-show="hasPermission(this.$route.name, 'Update')">重置密码</span>
					</template>
				</el-table-column>
                <!-- <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column> -->
                <el-table-column width="130" prop="express_name" label="收货物流"></el-table-column>
                <el-table-column min-width="50" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addUser ref="addUserFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addUser>
		<editSuperAgent ref="editSuperAgentRef" v-if="show_edit_super_dialog" @refreshData="getData" @closed="show_edit_super_dialog=false"></editSuperAgent>
		
		<udateAgentPassword ref="updateAgentPwd" @refreshData="getData" v-if="is_show_update_box" @closed="is_show_update_box = false">
        </udateAgentPassword>
		<el-dialog title="服务区域详情" v-model="is_show_agent_area" width="30%" :append-to-body="true" >
			<el-form ref="agentAreaForm" :model="agent_area_form"  label-width="80px">
				<el-form-item label="省/直辖市">
					<el-input v-model="agent_area_form.province" readonly></el-input>
				</el-form-item>
				<el-form-item label="市/州">
					<el-input v-model="agent_area_form.city" readonly></el-input>
				</el-form-item>
				<el-form-item label="区/县">
					<el-input type="textarea" v-model="agent_area_form.county_name" readonly></el-input>
				</el-form-item>
				<el-form-item >
					<el-button type="primary" @click="closeAgentArea">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog :title="salesman_title"  v-model="is_show_edit_sales_dialog" width="30%">
			<el-form ref="salesmanForm" label-width="80px">
				<el-form-item label="关联人员">
					<el-select v-model="salesman_form.sales_admin_id" placeholder="请选择,为空表示删除关联人员" style="width: 100%;" filterable
						clearable>
						<el-option v-for=" item  in  admin_list " :key="item.id" :label="item.name"
							:value="item.id" />
					</el-select>
					
				</el-form-item>
				<el-form-item >
					<el-button  @click="is_show_edit_sales_dialog=false">关闭</el-button>
					<el-button type="primary" @click="updateSalesman">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<el-dialog :title="send_type_title"  v-model="show_update_send_type" width="45%">
			<el-form ref="sendTypeForm" label-width="150px">
				<el-form-item label="是否可以直发用户:">
					<el-radio-group v-model="sendtype_form.can_self_pickup">
						<el-radio :value="false">不可以</el-radio>
						<el-radio :value="true">可以</el-radio>
					</el-radio-group>
					
				</el-form-item>
				<el-form-item label="直发用户收入比例:" v-if="sendtype_form.can_self_pickup">
					<div style="display: flex;"><el-input v-model="sendtype_form.self_pickup_income_ratio" style="width: 80px;"></el-input>
					<p>%  </p>
					<p style="margin-left: 4px;font-size: 10px;">如填50表示直发用户的订单,用户所在地区代理可以获得原收入的50%</p>
					</div>
					
					</el-form-item>
				
				<el-form-item >
					<el-button  @click="show_update_send_type=false">关闭</el-button>
					<el-button type="primary" @click="confirmUpdateSendType">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
    </div>
</template>
<script>
import addUser from "./components/addUserAgent";
import editSuperAgent from "./components/editSuperAgent";
import udateAgentPassword from "./components/updateAgentPassword";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersAgentUsers, UsersAgentUsersDelete,manageAgentStatus,editAgentSalesmanApi } from '@/api/api'
import{loadAdminListApi} from "@/api/system"
import { operateSuperAgentAPi } from '@/api/super_agent.js'
import { updateAgentSendTypeApi } from '@/api/agent'
export default {
    components: {
        Pagination,
        addUser,
		udateAgentPassword,
		editSuperAgent
    },
    name: 'agentUserManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			is_show_update_box:false,
			is_show_agent_area:false,
			agent_area_form:{
				province:"",
				city:"",
				county_name:""
			},
			show_edit_super_dialog:false,
			salesman_title:"",
			is_show_edit_sales_dialog:false,
			salesman_act_type_list:[{"name":"新增"},{"name":"编辑"}],
			salesman_form:{
				agent_id:0,
				sales_admin_id:0,
				
			},
			admin_list:[],
			
			sendtype_form:{
				agent_id:0,
				act_type:"",
				can_self_pickup:false,
				self_pickup_income_ratio:0,
			},
			
			send_type_title:"",
			show_update_send_type:false,
			
			
        }
    },

    methods: {
		updateSendType(row,act_type){
			let msg=""
			this.send_type_title="花材直发用户设置--("+(row.name)+")"
			
			this.show_update_send_type=true
			this.sendtype_form.agent_id=row.id
			this.sendtype_form.can_self_pickup=row.can_self_pickup
			this.sendtype_form.self_pickup_income_ratio=row.self_pickup_income_ratio
			
		},
		confirmUpdateSendType(){
			
			let msg=""
			if(this.sendtype_form.can_self_pickup){
				msg="确定该地区用户的花材可以直接发给用户嘛？直发给用户的订单该地区代理可以获得原收入的"+this.sendtype_form.self_pickup_income_ratio+"%"
			}else{
				msg='确定该地区用户的花材只能经过代理配送吗？'
			}
			var that=this
			that.$confirm(msg,"提示",{
				closeOnClickModal: false,
				type: "warning"
			}).then(res=>{
				
				updateAgentSendTypeApi({agent_id:that.sendtype_form.agent_id,can_self_pickup:that.sendtype_form.can_self_pickup,self_pickup_income_ratio:that.sendtype_form.self_pickup_income_ratio}).then(res=>{
					if(res.code==2000){
					 that.$message.success(res.msg)
					 that.getData()
					 that.show_update_send_type=false
					}else{
						 that.$message.warning(res.msg)
					}
				})
				
			}).catch(()=>{
				
			})
				
			
			
		},
		
		loadAdminList(){
			loadAdminListApi({page:1,limit:99,status:"1"}).then(res=>{
				if(res.code==2000){
					this.admin_list=res.data.data
				}
			})
		},
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		closeAgentArea(){
			this.is_show_agent_area=false
		},
		lookAgentArea(item){
			
		    if (item.county_table_list){
				item.county_table_list.forEach(e=>{
					this.agent_area_form.province=e.province
					this.agent_area_form.city=e.city
				})
				
			}
			
			this.agent_area_form.county_name=item.county_name
			this.is_show_agent_area=true
			
		},
        changeStatus(row) {
            let that = this
            let flat = row.is_active
            row.is_active = !row.is_active
            let temp_is_allow = 1
			
			let act=""
			let msg=""
			if(row.status){
				act="start"
				msg="确定启用该代理吗？"
			}else{
				act='stop'
				msg="确定停用该代理吗？停用后该区域用户的订单将不会经过该代理！"
			}
			
			
            that.$confirm(msg, '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
               
                manageAgentStatus({ id: row.id ,act:act}).then(res => {
                    if (res.code == 2000) {
                        that.$message.success(res.msg)
                        that.getData()
                    } else {
						row.status=!row.status
                        that.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addUserFlag.handleOpen(null, "新增")
            })
        },
        handleEdit(row, flag) {
			if(flag=="salesman"){
				this.is_show_edit_sales_dialog=true
				this.salesman_form.agent_id=row.id
				this.salesman_form.sales_admin_id=row.salesman_id
				this.salesman_form.sales_admin_name=row.sales_admin_name
				this.salesman_title="修改关联的运营人员--（"+ row.name+"）"
				return
			}
			
			if(flag=="updatePwd"){
				this.is_show_update_box = true
				this.$nextTick(() => {
				    this.$refs.updateAgentPwd.handleOpen(row, "重置密码")
				})
			}
			
            if (flag == 'edit') {
                this.isDialogVisible = true
                this.$nextTick(() => {
					
                    this.$refs.addUserFlag.handleOpen(row, "编辑")
                })
            }
            else if (flag == 'disable') {
                let vm = this
                manageAgentStatus({ id: row.id,act:'stop' }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.search()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除该代理吗？删除代理不会影响该地区的区域加价，删除后请注意检查该地区的区域加价是否需要修改', {
                    closeOnClickModal: false
                }).then(res => {
                    manageAgentStatus({ id: row.id ,act:'del'}).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
				
                this.getData()
            }
        },
		handleRenter(item,act_type){
			let msg=""
			if(act_type=="open"){
				msg="确定为"+item.name+"开通超级代理权限嘛？"
				
				this.show_edit_super_dialog=true
				
				let real_info={
					agent_id:item.id,
					real_name:item.real_name,
					phone:item.phone,
					
				}
				this.$nextTick(() => {
				    this.$refs.editSuperAgentRef.handleOpen(real_info, "开通超级代理")
				})
				return
			}
			if(act_type=="close"){
				msg="确定关闭"+item.name+"的超级代理权限吗？关闭后该超级代理名下的用户系统将不能使用！"
			}
			
			if(act_type=="stop"){
				msg="该超级代理权限正常使用中，确定停用"+item.name+"的超级代理权限吗？通用后该超级代理名下的用户系统将不能使用！"
			}
			if(act_type=="start"){
				msg="确定启用"+item.name+"的超级代理权限吗？"
			}
			this.$confirm(msg, {
			    closeOnClickModal: false
			}).then(res => {
			   operateSuperAgentAPi({agent_id:item.id,act_type:act_type}).then(res=>{
				   if(res.code==2000){
					   this.getData()
				   }else{
					   this.$message(res.msg)
				   }
			   })
			}).catch(() => {
			   console.log("cancel")
			})
			
		},
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            UsersAgentUsers(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
					
                    this.tableData = res.data.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		updateSalesman(){
			var that=this
			let msg=""
			
			if(this.salesman_form.sales_admin_id<1 || !this.salesman_form.sales_admin_id){
				msg="确定移除关联的后台运营人员吗？"
				this.salesman_form.sales_admin_id=0
			}
			if(this.salesman_form.sales_admin_id>0){
				msg="确定修改关联的后台运营人员吗？"
			}
			that.$confirm(msg,"提示", {
			    closeOnClickModal: false
			}).then(res=>{
				editAgentSalesmanApi({agent_id:that.salesman_form.agent_id,admin_id:that.salesman_form.sales_admin_id}).then(req_res=>{
					if (req_res.code==2000){
						that.$message(req_res.msg)
						that.getData()
						that.is_show_edit_sales_dialog=false
					}
				})
			}).catch()
			
		}

    },
    created() {
        this.getData()
		this.loadAdminList()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
