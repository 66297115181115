import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// 获取登录页的信息
export const login = (params) => ajaxPost({ url: `token/`, params });
// 获取验证码
export const getCaptcha = (params) => ajaxGet({ url: `captcha/`, params });
// 获取菜单
export const apiSystemWebRouter = (params) =>
  ajaxGet({ url: `system/menu/web_router/`, params });
//系统监控
export const monitorGetSystemInfo = (params) =>
  ajaxGet({ url: `monitor/getsysteminfo/`, params });
// 获取系统所有api列表
export const getSystemLyapiList = (params) =>
  ajaxGet({ url: `lyapi.json`, params });

/**
 *商家公告
 * */

// 商家公告
export const fmallNoticeshop = (params) =>
  ajaxGet({ url: `fmall/noticeshop/`, params });
// 商家公告 -- 新增
export const fmallNoticeshopAdd = (params) =>
  ajaxPost({ url: `fmall/noticeshop/`, params });
// 商家公告 -- 编辑
export const fmallNoticeshopEdit = (params) =>
  ajaxPut({ url: `fmall/noticeshop/`, params });
// 商家公告 -- 删除
export const fmallNoticeshopDelete = (params) =>
  ajaxDelete({ url: `fmall/noticeshop/`, params });

// 关键词管理
export const fmallKeywords = (params) =>
  ajaxGet({ url: `fmall/keywords/`, params });
// 关键词管理 -- 新增
export const fmallKeywordsAdd = (params) =>
  ajaxPost({ url: `fmall/keywords/`, params });
// 关键词管理 -- 编辑
export const fmallKeywordsEdit = (params) =>
  ajaxPut({ url: `fmall/keywords/`, params });
// 关键词管理 -- 删除
export const fmallKeywordsDelete = (params) =>
  ajaxDelete({ url: `fmall/keywords/`, params });



// 首页ICON
// export const fmallIconindex = (params) =>
//   ajaxGet({ url: `fmall/iconindex/`, params });
export const fmallIconindex = (params) =>
  ajaxGet({ url: `fmall/app/index-menu`, params });
// 首页ICON -- 新增
export const fmallIconindexAdd = (params) =>
  ajaxPost({ url: `fmall/iconindex/`, params });
// 首页ICON -- 编辑
export const fmallIconindexEdit = (params) =>
  ajaxPut({ url: `fmall/iconindex/`, params });
// 首页ICON -- 删除
export const fmallIconindexDelete = (params) =>
  ajaxDelete({ url: `fmall/iconindex/`, params });

// 等级管理
export const fmallGoodslevel = (params) =>
  ajaxGet({ url: `fmall/goodslevel/`, params });

export const loadLevelListApi = (params={}) =>ajaxGet({url:'product/level/list', params})
export const addLevelApi = (params) =>
  ajaxPost({ url: `product/level/add`, params });

export const editLevelApi = (params) =>
  ajaxPost({ url: `product/level/edit`, params });
  
export const manageLevelApi = (params) =>
  ajaxPost({ url: `product/level/manage/status`, params });




// 颜色管理
export const fmallGoodscolor = (params) =>
  ajaxGet({ url: `fmall/goodscolor/`, params });
// 颜色管理 -- 新增
export const fmallGoodscolorAdd = (params) =>
  ajaxPost({ url: `fmall/goodscolor/`, params });
// 颜色管理 -- 编辑
export const fmallGoodscolorEdit = (params) =>
  ajaxPut({ url: `fmall/goodscolor/`, params });
// 颜色管理 -- 删除
export const fmallGoodscolorDelete = (params) =>
  ajaxDelete({ url: `fmall/goodscolor/`, params });

// 标签管理
export const fmallGoodslabel = (params) =>
  ajaxGet({ url: `fmall/goodslabel/`, params });
// 标签管理 -- 新增
export const fmallGoodslabelAdd = (params) =>
  ajaxPost({ url: `fmall/goodslabel/`, params });
// 标签管理 -- 编辑
export const fmallGoodslabelEdit = (params) =>
  ajaxPut({ url: `fmall/goodslabel/`, params });
// 标签管理 -- 删除
export const fmallGoodslabelDelete = (params) =>
  ajaxDelete({ url: `fmall/goodslabel/`, params });

// 商品分类
// export const fmallGoodstype = (params) =>
//   ajaxGet({ url: `fmall/goodstype/`, params });
export const fmallGoodstype = (params) =>
  ajaxGet({ url: `fmall/product/category/`, params });
// 商品分类 -- 新增
// export const fmallGoodstypeAdd = (params) =>
//   ajaxPost({ url: `fmall/goodstype/`, params });
export const fmallGoodstypeAdd = (params) =>
  ajaxPost({ url: `fmall/product/category/`, params });
// 商品分类 -- 编辑
// export const fmallGoodstypeEdit = (params) =>
//   ajaxPut({ url: `fmall/goodstype/`, params });
export const fmallGoodstypeEdit = (params) =>
  ajaxPut({ url: `fmall/product/category/`, params });
// 商品分类 -- 删除
export const fmallGoodstypeDelete = (params) =>
  ajaxDelete({ url: `fmall/goodstype/`, params });

// 商品品种
// export const fmallGoodsBreed = (params) =>
//   ajaxGet({ url: `fmall/goodsBreed/`, params });
export const fmallGoodsBreed = (params) =>
  ajaxGet({ url: `fmall/product/breeds/`, params });
// 商品品种 -- 新增
// export const fmallGoodsBreedAdd = (params) =>
//   ajaxPost({ url: `fmall/goodsBreed/`, params });
export const fmallGoodsBreedAdd = (params) =>
  ajaxPost({ url: `fmall/product/breeds/`, params });
// 商品品种 -- 编辑
// export const fmallGoodsBreedEdit = (params) =>
//   ajaxPut({ url: `fmall/goodsBreed/`, params });
export const fmallGoodsBreedEdit = (params) =>
  ajaxPut({ url: `fmall/product/breeds/`, params });
// 商品品种 -- 删除
export const fmallGoodsBreedDelete = (params) =>
  ajaxDelete({ url: `fmall/goodsBreed/`, params });
// 商品品种 -- 是否热门
export const fmallGoodsBreedIshot = (params) =>
  ajaxPut({ url: `fmall/goodsBreed/ishot/`, params });
// 商品品种 -- 品种配置
export const fmallGoodsBreedSaveConfig = (params) =>
  ajaxPost({ url: `fmall/goodsBreed/saveConfig/`, params });

// 商品管理
// export const fmallGoods = (params) => ajaxGet({ url: `fmall/goods/`, params });
export const loadProductListApi = (params) => ajaxGet({ url: `mall/product/list`, params });
export const manageProductStatusApi = (params) => ajaxPost({ url: `mall/product/manage/status`, params });


export const fmallGoods = (params) => ajaxGet({ url: `fmall/product/`, params });
// 商品管理 -- 新增
export const fmallGoodsAdd = (params) =>
  ajaxPost({ url: `fmall/product/create/`, params });
// 商品管理 -- 编辑
// export const fmallGoodsEdit = (params) =>
//   ajaxPut({ url: `fmall/goods/`, params });
export const fmallGoodsEdit = (params) =>
  ajaxPut({ url: `fmall/product/update/`, params });
// 商品管理 -- 删除
// export const fmallGoodsDelete = (params) =>
//   ajaxDelete({ url: `fmall/goods/`, params });
export const fmallGoodsDelete = (params) =>
  ajaxDelete({ url: `fmall/product/`, params });
// 商品管理 -- 是否推荐
// export const fmallGoodsIstuijian = (params) =>
//   ajaxPut({ url: `fmall/goods/is_tuijian/`, params });
export const fmallGoodsIstuijian = (params) =>
  ajaxPut({ url: `fmall/product/recommend/`, params });
// 商品管理 -- 禁用(上下架)
export const fmallGoodsDisable = (params) =>
  ajaxPut({ url: `fmall/product/status/`, params });
  //获取商品详情
export const fmallGoodsDetail = (params) =>
  ajaxGet({ url: `fmall/product/detail/`, params });
// 商品管理 根据品种ID获取等级列表
// export const fmallGoodsGetlevelList = (params) =>
//   ajaxGet({ url: `fmall/goods/getlevelList/`, params });
export const fmallGoodsGetlevelList = (params) =>
  ajaxGet({ url: `fmall/product/getLevelList/`, params });

// 活动管理
export const fmallActive = (params) =>
  ajaxGet({ url: `fmall/active/`, params });
// 活动管理 -- 新增
export const fmallActiveAdd = (params) =>
  ajaxPost({ url: `fmall/active/`, params });
// 活动管理 -- 编辑
export const fmallActiveEdit = (params) =>
  ajaxPut({ url: `fmall/active/`, params });
// 活动管理 -- 删除
export const fmallActiveDelete = (params) =>
  ajaxDelete({ url: `fmall/active/`, params });
// 活动管理 -- 禁用
export const fmallActiveDisable = (params) =>
  ajaxPut({ url: `fmall/active/status/`, params });

// 商家分类
export const fmallShoptype = (params) =>
  ajaxGet({ url: `fmall/shoptype/`, params });
// 商家分类 -- 新增
export const fmallShoptypeAdd = (params) =>
  ajaxPost({ url: `fmall/shoptype/`, params });
// 商家分类 -- 编辑
export const fmallShoptypeEdit = (params) =>
  ajaxPut({ url: `fmall/shoptype/`, params });
// 商家分类 -- 删除
export const fmallShoptypeDelete = (params) =>
  ajaxDelete({ url: `fmall/shoptype/`, params });

// 商家管理
export const fmallShop = (params) => ajaxGet({ url: `fmall/shop/`, params });
// 商家管理 -- 新增
export const fmallShopAdd = (params) =>
  ajaxPost({ url: `fmall/shop/`, params });
// 商家管理 -- 编辑
export const fmallShopEdit = (params) =>
  ajaxPut({ url: `fmall/shop/`, params });
// 商家管理 -- 删除
export const fmallShopDelete = (params) =>
  ajaxDelete({ url: `fmall/shop/`, params });
// 商家管理 -- 禁用
export const fmallShopDisable = (params) =>
  ajaxPut({ url: `fmall/shop/disableStatus/`, params });
// 商家管理 -- 审核
export const fmallShopAudit = (params) =>
  ajaxPost({ url: `fmall/shop/audit/`, params });

// 客服管理
export const fmallKefu = (params) => ajaxGet({ url: `fmall/kefu/`, params });
// 客服管理 -- 新增
export const fmallKefuAdd = (params) =>
  ajaxPost({ url: `fmall/kefu/`, params });
// 客服管理 -- 编辑
export const fmallKefuEdit = (params) =>
  ajaxPut({ url: `fmall/kefu/`, params });
// 客服管理 -- 删除
export const fmallKefuDelete = (params) =>
  ajaxDelete({ url: `fmall/kefu/`, params });

// 举报类型
export const fmallTipofftype = (params) =>
  ajaxGet({ url: `fmall/tipofftype/`, params });
// 举报类型 -- 新增
export const fmallTipofftypeAdd = (params) =>
  ajaxPost({ url: `fmall/tipofftype/`, params });
// 举报类型 -- 编辑
export const fmallTipofftypeEdit = (params) =>
  ajaxPut({ url: `fmall/tipofftype/`, params });
// 举报类型 -- 删除
export const fmallTipofftypeDelete = (params) =>
  ajaxDelete({ url: `fmall/tipofftype/`, params });

// 举报记录
export const fmallTipoffrecord = (params) =>
  ajaxGet({ url: `fmall/tipoffrecord/`, params });
// 举报记录 -- 新增
export const fmallTipoffrecordAdd = (params) =>
  ajaxPost({ url: `fmall/tipoffrecord/`, params });
// 举报记录 -- 编辑
export const fmallTipoffrecordEdit = (params) =>
  ajaxPut({ url: `fmall/tipoffrecord/`, params });
// 举报记录 -- 删除
export const fmallTipoffrecordDelete = (params) =>
  ajaxDelete({ url: `fmall/tipoffrecord/`, params });

// 举报记录
export const fmallCityshopim = (params) =>
  ajaxGet({ url: `fmall/cityshopim/`, params });
// 举报记录 -- 新增
export const fmallCityshopimdAdd = (params) =>
  ajaxPost({ url: `fmall/cityshopim/`, params });
// 举报记录 -- 编辑
export const fmallCityshopimEdit = (params) =>
  ajaxPut({ url: `fmall/cityshopim/`, params });
// 举报记录 -- 删除
export const fmallCityshopimDelete = (params) =>
  ajaxDelete({ url: `fmall/cityshopim/`, params });

// 仓库管理
export const fmallStoremgt = (params) =>
  ajaxGet({ url: `fmall/storemgt/`, params });
export const loadStoreListApi = (params) =>
  ajaxGet({ url: `mall/store/list/`, params });
// 仓库管理 
export const manageStoreStatusApi = (params) =>
  ajaxPost({ url: `mall/store/manage/status/`, params });
  
export const fmallStoremgtAdd = (params) =>
  ajaxPost({ url: `fmall/storemgt/`, params });
// 仓库管理 -- 编辑
export const fmallStoremgtEdit = (params) =>
  ajaxPut({ url: `fmall/storemgt/`, params });
// 仓库管理 -- 删除
export const fmallStoremgtDelete = (params) =>
  ajaxDelete({ url: `fmall/storemgt/`, params });

// 运费配置
// export const fmallFreightc = (params) =>
//   ajaxGet({ url: `fmall/freightc/`, params });
export const loadDeliveryListApi = (params) =>
  ajaxGet({ url: `delivery/list/`, params });//-----------
// 运费配置 -- 新增
// export const fmallFreightcAdd = (params) =>
//   ajaxPost({ url: `fmall/freightc/`, params });
export const addDeliveryApi = (params) =>
  ajaxPost({ url: `delivery/add/`, params });
export const manageDeliveryStatusApi = (params) =>
  ajaxPost({ url: `delivery/status/`, params });
// 运费配置 -- 编辑
export const fmallFreightcEdit = (params) =>
  ajaxPut({ url: `fmall/freightc/`, params });
  
export const deliveryEditApi = (params) =>
  ajaxPost({ url: `delivery/edit/`, params });
// 运费配置 -- 删除
export const fmallFreightcDelete = (params) =>
  ajaxDelete({ url: `fmall/freightc/`, params });
export const delDeliveryApi = (params) =>
  ajaxPost({ url: `delivery/del/`, params });

// 运费配置 查询选择过的区域
// export const fmallFreightcSelected = (params) =>
//   ajaxGet({ url: `fmall/freightc/getAllSelect/`, params });
export const loadDeliverySelectedAreaApi = (params) =>
  ajaxGet({ url: `delivery/getAllSelect/`, params });

// 优惠券管理
export const fmallGoodsCoupon = (params) =>
  ajaxGet({ url: `fmall/goodsCoupon/`, params });
// 优惠券管理 -- 新增
export const fmallGoodsCouponAdd = (params) =>
  ajaxPost({ url: `fmall/goodsCoupon/`, params });
// 优惠券管理 -- 编辑
export const fmallGoodsCouponEdit = (params) =>
  ajaxPut({ url: `fmall/goodsCoupon/`, params });
// 优惠券管理 -- 删除
export const fmallGoodsCouponDelete = (params) =>
  ajaxDelete({ url: `fmall/goodsCoupon/`, params });
// 优惠券管理 -- 禁用
export const fmallGoodsCouponDisable = (params) =>
  ajaxPut({ url: `fmall/goodsCoupon/disableStatus/`, params });

// 优惠券领取记录
export const fmallGoodsCouponRecord = (params) =>
  ajaxGet({ url: `fmall/goodsCouponRecord/`, params });

/**
 *系统配置
 * */

// 系统配置
export const platformsettingsSysconfig = (params) =>
  ajaxGet({ url: `platformsettings/sysconfig/`, params });
// 系统配置 -- 新增
export const platformsettingsSysconfigAdd = (params) =>
  ajaxPost({ url: `platformsettings/sysconfig/`, params });
// 系统配置 -- 编辑
export const platformsettingsSysconfigEdit = (params) =>
  ajaxPut({ url: `platformsettings/sysconfig/`, params });
// 系统配置 -- 删除
export const platformsettingsSysconfigDelete = (params) =>
  ajaxDelete({ url: `platformsettings/sysconfig/`, params });
// 系统配置 -- 保存子项
export const platformsettingsSysconfigSavecontent = (params) =>
  ajaxPut({ url: `platformsettings/sysconfig/save_content/`, params });

/**
 *计划任务
 * */

// 计划任务
export const crontabPeriodictask = (params) =>
  ajaxGet({ url: `crontab/periodictask/`, params });
// 计划任务 -- 新增
export const crontabPeriodictaskAdd = (params) =>
  ajaxPost({ url: `crontab/periodictask/`, params });
// 计划任务 -- 编辑
export const crontabPeriodictaskEdit = (params) =>
  ajaxPut({ url: `crontab/periodictask/`, params });
// 计划任务 -- 删除
export const crontabPeriodictaskDelete = (params) =>
  ajaxDelete({ url: `crontab/periodictask/`, params });
// 计划任务 -- 开始/暂停
export const crontabPeriodictaskEnabled = (params) =>
  ajaxPut({ url: `crontab/periodictask/enabled/`, params });
// 计划任务 获取本地所有tasks文件中的task任务方法
export const crontabPeriodictaskMethodlist = (params) =>
  ajaxGet({ url: `crontab/periodictask/tasklist/`, params });

// 计划任务 获取任务执行结果
export const crontabTaskresult = (params) =>
  ajaxGet({ url: `crontab/taskresult/`, params });

/**
 *终端服务
 * */

// 终端服务
export const apiTerminal = (params) =>
  ajaxGet({ url: `terminal/terminal/`, params });
// 终端服务 -- 新增
export const apiTerminalAdd = (params) =>
  ajaxPost({ url: `terminal/terminal/`, params });
// 终端服务 -- 编辑
export const apiTerminalEdit = (params) =>
  ajaxPut({ url: `terminal/terminal/`, params });
// 终端服务 -- 删除
export const apiTerminalDelete = (params) =>
  ajaxDelete({ url: `terminal/terminal/`, params });

/**
 *系统管理
 * */
// 部门管理列表
export const apiSystemDept = (params) =>
  ajaxGet({ url: `system/dept/`, params });
// 部门管理列表 -- 新增部门
export const apiSystemDeptAdd = (params) =>
  ajaxPost({ url: `system/dept/`, params });
// 部门管理列表 -- 编辑部门
export const apiSystemDeptEdit = (params) =>
  ajaxPut({ url: `system/dept/`, params });
// 部门管理列表 -- 删除部门
export const apiSystemDeptDelete = (params) =>
  ajaxDelete({ url: `system/dept/`, params });

// 菜单管理列表
export const apiSystemMenu = (params) =>
  ajaxGet({ url: `system/menu/`, params });
// 菜单管理列表 -- 新增菜单
export const apiSystemMenuAdd = (params) =>
  ajaxPost({ url: `system/menu/`, params });
// 菜单管理列表 -- 编辑菜单
export const apiSystemMenuEdit = (params) =>
  ajaxPut({ url: `system/menu/`, params });
// 菜单管理列表 -- 删除菜单
export const apiSystemMenuDelete = (params) =>
  ajaxDelete({ url: `system/menu/`, params });
//获取部门数据,获取菜单树
export const systemMenuTree = (params) =>
  ajaxGet({ url: `system/menu_tree/`, params });
// 菜单管理 》 按钮列表(全部)
export const systemButton = (params) =>
  ajaxGet({ url: `system/button/`, params });
// 菜单管理 》 按钮 编辑
export const systemButtonEdit = (params) =>
  ajaxPut({ url: `system/button/`, params });
// 菜单管理 》 按钮 新增
export const systemButtonAdd = (params) =>
  ajaxPost({ url: `system/button/`, params });
// 菜单管理 》 按钮 删除
export const systemButtonDelete = (params) =>
  ajaxDelete({ url: `system/button/`, params });
// 菜单管理 》 按钮配置列表（针对单个菜单）
export const systemMenuButton = (params) =>
  ajaxGet({ url: `system/menu_button/`, params });
// 菜单管理 》 按钮配置列表（针对单个菜单） 新增
export const systemMenuButtonAdd = (params) =>
  ajaxPost({ url: `system/menu_button/`, params });
// 菜单管理 》 按钮配置列表（针对单个菜单） 编辑
export const systemMenuButtonEdit = (params) =>
  ajaxPut({ url: `system/menu_button/`, params });
// 菜单管理 》 按钮配置列表（针对单个菜单） 删除
export const systemMenuButtonDelete = (params) =>
  ajaxDelete({ url: `system/menu_button/`, params });

// 角色管理列表
export const apiSystemRole = (params) =>
  ajaxGet({ url: `system/role/`, params });
// 角色管理列表-修改
export const apiSystemRoleEdit = (params) =>
  ajaxPut({ url: `system/role/`, params });
// 角色管理列表 -- 新增角色
export const apiSystemRoleAdd = (params) =>
  ajaxPost({ url: `system/role/`, params });
// 角色管理列表 -- 新增角色
export const apiSystemRoleDelete = (params) =>
  ajaxDelete({ url: `system/role/`, params });
//获取部门数据,用于角色授权
export const apiSystemDeptTree = (params) =>
  ajaxGet({ url: `system/dept_tree/`, params });
//通过角色id,获取菜单数据
export const apiSystemRoleIdToMenu = (params) =>
  ajaxGet({ url: `system/role_id_to_menu/`, params });
export const apiSystemRoleIdToMenuid = (id) =>
  ajaxGet({ url: `system/role_id_to_menu/` + id + "/" });

//权限管理
// 权限管理 -- 保存
export const apiPermissionSave = (params) =>
  ajaxPut({ url: `system/permission/`, params });

//管理员管理
export const apiSystemUser = (params) =>
  ajaxGet({ url: `system/user/`, params });
//管理员管理-新增
export const apiSystemUserAdd = (params) =>
  ajaxPost({ url: `system/user/`, params });
//管理员管理-修改
export const apiSystemUserEdit = (params) =>
  ajaxPut({ url: `system/user/`, params });
//管理员管理-删除
export const apiSystemUserDelte = (params) =>
  ajaxDelete({ url: `system/user/`, params });

/**
 *日志管理
 * */
// 日志管理 查询
export const systemOperationlog = (params) =>
  ajaxGet({ url: `system/operation_log/`, params });
// 日志管理 删除
export const systemOperationlogDelete = (params) =>
  ajaxDelete({ url: `system/operation_log/`, params });
// 日志管理 清空全部日志
export const systemOperationlogDeletealllogsDelete = (params) =>
  ajaxDelete({ url: `system/operation_log/deletealllogs/`, params });

/**
 *个人中心
 * */
// 获取当前个人用户信息
export const systemUserUserInfo = (params) =>
  ajaxGet({ url: `system/user/user_info/`, params });
// 更新修改当前个人用户信息
export const systemUserUserInfoEdit = (params) =>
  ajaxPut({ url: `system/user/user_info/`, params });
// 用户重置个人密码
export const systemUserChangePassword = (params) =>
  ajaxPut({ url: `system/user/change_password/`, params });

/**
 *消息中心
 * */
//消息模板
export const messagesMessagetemplate = (params) =>
  ajaxGet({ url: `messages/messagetemplate/`, params });
//消息模板-新增
export const messagesMessagetemplateAdd = (params) =>
  ajaxPost({ url: `messages/messagetemplate/`, params });
//消息模板-修改
export const messagesMessagetemplateEdit = (params) =>
  ajaxPut({ url: `messages/messagetemplate/`, params });
//消息模板-删除
export const messagesMessagetemplateDelete = (params) =>
  ajaxDelete({ url: `messages/messagetemplate/`, params });

//消息公告
export const fmallNotice = (params) =>
  ajaxGet({ url: `fmall/notice/`, params });
//消息公告-新增
export const fmallNoticeAdd = (params) =>
  ajaxPost({ url: `fmall/notice/`, params });
//消息公告-修改
export const fmallNoticeEdit = (params) =>
  ajaxPut({ url: `fmall/notice/`, params });
//消息公告-删除
export const fmallNoticeDelete = (params) =>
  ajaxDelete({ url: `fmall/notice/`, params });
//消息公告-禁用
export const fmallNoticeDisable = (params) =>
  ajaxDelete({ url: `fmall/notice/disablestatus/`, params });

/**
 *省市区选择
 * */
// 省市区选择  获取省
export const getProvince = (params) => ajaxGet({ url: `areas/`, params });
// 省市区选择  获取市/区
export const getCityDistrictByID = (params) =>
  ajaxGetDetailByID({ url: `areas/`, params });

// 省市区选择  根据详细地址获取经纬度
export const getAddressaccuracy = (params) =>
  ajaxGet({ url: `getaddressaccuracy/`, params });

// 省市区选择  获取全部
export const getAllAreaslist = (params) =>
  ajaxGet({ url: `getallareaslist/`, params }); //正常使用

/**
 *意见反馈
 * */

// 意见反馈 列表
export const PlatformsettingsUserfeeckback = (params) =>
  ajaxGet({ url: `platformsettings/userfeeckback/`, params });
// 意见反馈 新增
export const PlatformsettingsUserfeeckbackAdd = (params) =>
  ajaxPost({ url: `platformsettings/userfeeckback/`, params });
// 意见反馈 编辑
export const PlatformsettingsUserfeeckbackEdit = (params) =>
  ajaxPut({ url: `platformsettings/userfeeckback/`, params });
// 意见反馈 删除
export const PlatformsettingsUserfeeckbackDelete = (params) =>
  ajaxDelete({ url: `platformsettings/userfeeckback/`, params });

/**
 *地区管理
 * */

// 地区管理列表
export const addressArea = (params) =>
  ajaxGet({ url: `address/area/`, params });
// 地区管理列表 获取根地区
export const addressAreaRoot = (params) =>
  ajaxGet({ url: `address/area/area_root/`, params });
// 地区管理列表 -- 新增
export const addressAreaAdd = (params) =>
  ajaxPost({ url: `address/area/`, params });
// 地区管理列表 -- 编辑
export const addressAreaEdit = (params) =>
  ajaxPut({ url: `address/area/`, params });
// 地区管理列表 -- 删除
export const addressAreaDelete = (params) =>
  ajaxDelete({ url: `address/area/`, params });

/**
 *商城管理
 * */

// 商品分类 列表
export const mallGoodstype = (params) =>
  ajaxGet({ url: `mall/goodstype/`, params });
// 商品分类 新增
export const mallGoodstypeAdd = (params) =>
  ajaxPost({ url: `mall/goodstype/`, params });
// 商品分类 编辑
export const mallGoodstypeEdit = (params) =>
  ajaxPut({ url: `mall/goodstype/`, params });
// 商品分类 删除
export const mallGoodstypeDelete = (params) =>
  ajaxDelete({ url: `mall/goodstype/`, params });

// 商品管理 列表
export const mallGoodsspu = (params) =>
  ajaxGet({ url: `mall/goodsspu/`, params });
// 商品管理 新增
export const mallGoodsspuAdd = (params) =>
  ajaxPost({ url: `mall/goodsspu/`, params });
// 商品管理 编辑
export const mallGoodsspuEdit = (params) =>
  ajaxPut({ url: `mall/goodsspu/`, params });
// 商品管理 删除
export const mallGoodsspuDelete = (params) =>
  ajaxDelete({ url: `mall/goodsspu/`, params });

// 商品管理 上下架
export const mallGoodsspuIslaunched = (params) =>
  ajaxPut({ url: `mall/goodsspu/islaunched/`, params });

// 商品管理 修改商品SKU价格
export const mallGoodsspueEditskups = (params) =>
  ajaxPut({ url: `mall/goodsspu/editskups/`, params });

// 商城订单 列表
export const mallGoodsOrder = (params) =>
  ajaxGet({ url: `mall/goodsorder/`, params });
// 商城订单 新增
export const mallGoodsOrderAdd = (params) =>
  ajaxPost({ url: `mall/goodsorder/`, params });
// 商城订单 编辑
export const mallGoodsOrderEdit = (params) =>
  ajaxPut({ url: `mall/goodsorder/`, params });
// 商城订单 删除
export const mallGoodsOrderDelete = (params) =>
  ajaxDelete({ url: `mall/goodsorder/`, params });

// 商城订单 发货
export const mallGoodsSendoutgoods = (params) =>
  ajaxPost({ url: `mall/goodsorder/sendoutgoods/`, params });

// 商城订单 订单价格 订单量统计
export const mallGoodsOrderstatistics = (params) =>
  ajaxGet({ url: `mall/goodsorder/orderstatistics/`, params });

//营销管理

// 优惠券管理 列表
export const mallGoodscoupon = (params) =>
  ajaxGet({ url: `mall/goodscoupon/`, params });
// 优惠券管理 新增
export const mallGoodscouponAdd = (params) =>
  ajaxPost({ url: `mall/goodscoupon/`, params });
// 优惠券管理 编辑
export const mallGoodscouponEdit = (params) =>
  ajaxPut({ url: `mall/goodscoupon/`, params });
// 优惠券管理 删除
export const mallGoodscouponDelete = (params) =>
  ajaxDelete({ url: `mall/goodscoupon/`, params });

// 优惠券记录 列表
export const mallCouponrecord = (params) =>
  ajaxGet({ url: `mall/couponrecord/`, params });
// 优惠券记录 新增
export const mallCouponrecordAdd = (params) =>
  ajaxPost({ url: `mall/couponrecord/`, params });
// 优惠券记录 编辑
export const mallCouponrecordEdit = (params) =>
  ajaxPut({ url: `mall/couponrecord/`, params });
// 优惠券记录 删除
export const mallCouponrecordDelete = (params) =>
  ajaxDelete({ url: `mall/couponrecord/`, params });

/**
 * 财务统计
 * **/


//商品订单统计
export const getOrderListApi = (params) =>
  ajaxGet({ url: `mall/orders/list/`, params });
export const getOrdersAgentListApi = (params) =>
  ajaxGet({ url: `mall/orders/agent/list/`, params });
  
export const getOrdersDetailApi = (params) =>ajaxGet({ url: `mall/orders/detail/`, params });
  
//商品订单统计
export const mallGoodsforderinfo = (params) =>
  ajaxGet({ url: `mall/goodsforderinfo/`, params });
// 商品订单统计 新增
export const mallGoodsforderinfoAdd = (params) =>
  ajaxPost({ url: `mall/goodsforderinfo/`, params });
// 商品订单统计 编辑
export const mallGoodsforderinfoEdit = (params) =>
  ajaxPut({ url: `mall/goodsforderinfo/`, params });
// 商品订单统计 删除
export const mallGoodsforderinfoDelete = (params) =>
  ajaxDelete({ url: `mall/goodsforderinfo/`, params });
// 商品订单统计 总金额统计
export const mallGoodsforderinfoOrderstatistics = (params) =>
  ajaxGet({ url: `mall/goodsforderinfo/orderstatistics/`, params });

/**
 *用户管理
 * */

// 用户管理 列表
export const UsersUsers = (params) => ajaxGet({ url: `users/users/`, params });
// 用户管理 新增
export const UsersUsersAdd = (params) =>
  ajaxPost({ url: `users/users/`, params });
// 用户管理 编辑
export const UsersUsersEdit = (params) =>
  ajaxPut({ url: `users/users/`, params });
// 用户管理 删除
export const UsersUsersDelete = (params) =>
  ajaxDelete({ url: `users/users/`, params });
// 用户管理 禁用用户
export const UsersUsersdisableEdit = (params) =>
  ajaxPut({ url: `users/users/disableuser/`, params });
// 用户管理 导出
export const UsersUsersExportexecl = (params) =>
  ajaxGet({ url: `users/users/exportexecl/`, params });

/**
 *代理管理
 * */

// 代理管理 列表
// export const UsersAgentUsers = (params) =>
//   ajaxGet({ url: `users/agentUsers/`, params });
export const UsersAgentUsers = (params) =>
  ajaxGet({ url: `agent/list/`, params });
// 代理管理 新增
// export const UsersAgentUsersAdd = (params) =>
//   ajaxPost({ url: `agent/agentUsers/`, params });
export const UsersAgentUsersAdd = (params) =>
  ajaxPost({ url: `agent/add/`, params });
// 代理管理 编辑
// export const UsersAgentUsersEdit = (params) =>
//   ajaxPut({ url: `users/agentUsers/`, params });
export const UsersAgentUsersEdit = (params) =>
  ajaxPost({ url: `agent/edit/`, params });
// 代理管理 删除
export const manageAgentStatus = (params) =>
  ajaxPost({ url: `agent/manage/status/`, params });
  
export const updateAgentPasswordApi = (params) =>
  ajaxPost({ url: `agent/update/password/`, params });
  
export const editAgentSalesmanApi = (params) =>
  ajaxPost({ url: `agent/edit/salesman`, params });
  
// export const UsersAgentUsersDelete = (params) =>
//   ajaxDelete({ url: `users/agentUsers/`, params });
// // 代理管理 禁用用户
// export const UsersAgentUsersDisable = (params) =>
//   ajaxPut({ url: `users/agentUsers/disableuser/`, params });

/**
 *平台设置
 */
//轮播图列表
export const platformsettingsLunboimg = (params) =>
  ajaxGet({ url: `platformsettings/lunboimg/`, params });
// 轮播图列表 新增
export const platformsettingsLunboimgAdd = (params) =>
  ajaxPost({ url: `platformsettings/lunboimg/`, params });
// 轮播图列表 编辑
export const platformsettingsLunboimgEdit = (params) =>
  ajaxPut({ url: `platformsettings/lunboimg/`, params });
// 轮播图列表 删除
export const platformsettingsLunboimgDelete = (params) =>
  ajaxDelete({ url: `platformsettings/lunboimg/`, params });

//其他设置
export const platformsettingsOther = (params) =>
  ajaxGet({ url: `platformsettings/other/`, params });
// 其他设置 新增
export const platformsettingsOtherAdd = (params) =>
  ajaxPost({ url: `platformsettings/other/`, params });
// 其他设置 编辑
export const platformsettingsOtherEdit = (params) =>
  ajaxPut({ url: `platformsettings/other/`, params });
// 其他设置 删除
export const platformsettingsOtherDelete = (params) =>
  ajaxDelete({ url: `platformsettings/other/`, params });

// 平台设置 图片上传
export const platformsettingsUploadPlatformImg = (params) =>
  uploadImg({ url: `platformsettings/uploadplatformimg/`, params });

// 平台设置 文件上传
export const platformsettingsUploadPlatformFile = (params) =>
  uploadImg({ url: `platformsettings/uploadplatformfile/`, params });

//前端访问操作 获取
export const superOerateGet = (params) =>
  ajaxGet({ url: `super/operate/`, params });
//前端访问操作 设置
export const superOerateSet = (params) =>
  ajaxPost({ url: `super/operate/`, params });

//提现管理
export const fmallBlcRecord = (params) =>
  ajaxGet({ url: `fmall/blcRecord/`, params });
// 提现管理 审核
export const fmallBlcRecordAudit = (params) =>
  ajaxPost({ url: `fmall/blcRecord/audit/`, params });

//代理提现管理
export const fmallBlcAgentRecord = (params) =>
  ajaxGet({ url: `fmall/blcAgentRecord/`, params });
// 代理提现管理 提现申请
export const fmallblcAgentRecordApply = (params) =>
  ajaxPost({ url: `fmall/blcAgentRecord/apply/`, params });

// 代理提现管理 统计
export const fmallblcAgentRecordStatic = (params) =>
  ajaxGet({ url: `fmall/blcAgentRecord/orderstatistics/`, params });

// 订单管理
export const fmallOrder = (params) => ajaxGet({ url: `fmall/order/`, params });
// 订单管理 新增
export const fmallOrderAdd = (params) =>
  ajaxPost({ url: `fmall/order/`, params });
  
export const setOrderPackagesApi = (params) =>
  ajaxPost({ url: `mall/orders/product/set/package/`, params });
export const setOrderWeightApi = (params) =>
  ajaxPost({ url: `mall/orders/product/set/weight/`, params });
// 订单管理 编辑
export const fmallOrderEdit = (params) =>
  ajaxPut({ url: `fmall/order/`, params });
// 订单管理 删除
export const fmallOrderDelete = (params) =>
  ajaxDelete({ url: `fmall/order/`, params });
// 订单管理 服务商发货
export const fmallOrderAgentSend = (params) =>
    ajaxPost({ url: `fmall/order/agentSend/`, params });
// 订单管理 详情
export const fmallOrderDetail = (params) =>
  ajaxGetDetailByID({ url: `fmall/order/`, params });
// 订单管理 操作记录
export const fmallOrderOperateLog = (params) =>
  ajaxGetDetailByID({ url: `fmall/order/operateLog/`, params });
// 订单管理 运费
export const fmallOrderYunfeibk = (params) =>
  ajaxPost({ url: `fmall/order/yunfeibk/`, params });
// 订单管理 打印货单
export const fmallOrderPrintOrder = (params) =>
  ajaxPost({ url: `fmall/order/printOrder/`, params });

// 订单管理 发货
export const fmallOrderSendgoods = (params) =>
  ajaxPost({ url: `fmall/order/sendgoods/`, params });

// 订单管理 物流查询
export const orderLogisticsSearch = (params) =>
  ajaxGet({ url: `app/getKuaidiDetail/`, params });



// 入位管理
export const loadOrdersProductListApi = (params) =>
  ajaxGet({ url: `mall/orders/product/list/`, params });
export const updateRuWeiApi = (params) =>
    ajaxPost({ url: `mall/orders/product/ru-wei/update/`, params });
export const confirmSendApi = (params) =>
    ajaxPost({ url: `mall/orders/product/confirm/send/`, params });
	
export const handlePushPosNodApi = (params) =>
    ajaxPost({ url: `mall/handle/push/pos-no`, params });
	
export const cancelOrderApi = (params) =>
    ajaxPost({ url: `mall/cancel/order`, params });
	
	
export const agreeRefundApi = (params) =>
    ajaxPost({ url: `mall/orders/refund/agree`, params });
	
export const loadRefundListApi = (params) =>
    ajaxGet({ url: `mall/orders/refund/list`, params });
	
	
	
export const loadOrdersExpressListApi = (params) =>
    ajaxGet({ url: `mall/orders/load/express/list/`, params });
  
export const fmallOrdergoods = (params) =>
  ajaxGet({ url: `fmall/ordergoods/`, params });
// 入位管理 新增
export const fmallOrdergoodsAdd = (params) =>
  ajaxPost({ url: `fmall/ordergoods/`, params });
// 入位管理 编辑
export const fmallOrdergoodsEdit = (params) =>
  ajaxPut({ url: `fmall/ordergoods/`, params });
// 入位管理 删除
export const fmallOrdergoodsDelete = (params) =>
  ajaxDelete({ url: `fmall/ordergoods/`, params });
// 入位管理 入位
export const fmallOrdergoodsRuwei = (params) =>
  ajaxPost({ url: `fmall/ordergoods/ruwei/`, params });

// 售后订单管理
export const fmallOrderaftersales = (params) =>
  ajaxGet({ url: `fmall/orderaftersales/`, params });
  
export const laodAdterSalesListApi = (params) =>
  ajaxGet({ url: `after-sales/apply/list/`, params });
  
export const laodAdterSalesDetailApi = (params) =>
  ajaxGet({ url: `after-sales/apply/detail/`, params });
export const auditAfterSalesApplyApi = (params) =>
  ajaxPost({ url: `after-sales/audit/apply/`, params });

export const setOrderApplyAfterStatusAPi = (params) =>
  ajaxPost({ url: `mall/orders/update/after-sale/apply/status`, params });




// 售后订单管理 详情
export const fmallOrderaftersalesDetail = (params) =>
  ajaxGetDetailByID({ url: `fmall/orderaftersales/`, params });
// 售后订单管理 审核
export const fmallOrderaftersalesAudit = (params) =>
  ajaxPost({ url: `fmall/orderaftersales/audit/`, params });

// 代理统计 列表
export const fmallAgentorderstatic = (params) =>
  ajaxGet({ url: `fmall/agentorderstatic/`, params });

// 代理统计 统计
export const fmallAgentorderstaticStatics = (params) =>
  ajaxGet({ url: `fmall/agentorderstatic/statics/`, params });

// 代理统计 列表
export const fmallFinanceorder = (params) =>
  ajaxGet({ url: `fmall/financeorder/`, params });

// 代理统计 统计
export const fmallFinanceorderStatics = (params) =>
  ajaxGet({ url: `fmall/financeorder/statics/`, params });


// 商品规格详情
export const fmallLabels = (params) =>
ajaxGet({ url: `fmall/attribute/`, params });

export const loadAttrApi = (params) =>
ajaxGet({ url: `mall/product/attr/list`, params });


// 商品规格详情 新增
// export const fmallLabelsAdd = (params) =>
// ajaxPost({ url: `fmall/attribute/`, params });
// 商品规格详情 编辑
export const addAttrApi= (params) =>
ajaxPost({ url: `mall/product/attr/add`, params });
export const delAttrApi= (params) =>
ajaxPost({ url: `mall/product/attr/del`, params });

export const fmallLabelsEdit = (params) =>
ajaxPut({ url: `fmall/attribute/`, params });
// 商品规格详情 删除
export const fmallLabelsDelete = (params) =>
ajaxDelete({ url: `fmall/attribute/`, params });

// export const AreaPriceSearch = (params) => ajaxGet({ url: `fmall/areaPrice/`, params });
//区域加价管理
export const AreaPriceSearchApi = (params) => ajaxGet({ url: `agent/area-price/list/`, params });

export const AreaPriceAddApi = (params) => ajaxPost({ url: `agent/area-price/add/`, params });
export const AreaPriceEditApi = (params) => ajaxPost({ url: `agent/area-price/edit/`, params });
export const AreaPriceManageStatusApi = (params) => ajaxPost({ url: `agent/area-price/manage/status/`, params });
export const getAreaPriceApi = (params) => ajaxGet({ url: `agent/city/add-price`, params });

// export const AreaPriceDelete = (params) => ajaxDelete({ url: `fmall/areaPrice/`, params });
// export const AreaPriceDisable = (params) => ajaxPut({ url: `fmall/areaPrice/disable/`, params });


export const ExpressSearch = (params) => ajaxGet({ url: `fmall/express/`, params });
export const loadExpressListApi = (params) => ajaxGet({ url: `express/list/`, params });
// export const NormalExpressList = (params) => ajaxGet({ url: `fmall/express/normal_list/`, params });
export const NormalExpressListApi = (params) => ajaxGet({ url: `express/normal_list/`, params });
// export const ExpressAdd = (params) => ajaxPost({ url: `fmall/express/`, params });

// export const ExpressEdit = (params) => ajaxPut({ url: `fmall/express/`, params });

export const ExpressDelete = (params) => ajaxDelete({ url: `fmall/express/`, params });
export const ExpressDisable = (params) => ajaxPut({ url: `fmall/express/disable/`, params });


export const ExpressBoxSearch = (params) => ajaxGet({ url: `fmall/express_box/`, params });
export const loadExpressBoxListApi = (params) => ajaxGet({ url: `fmall/express_box/`, params });
export const NormalExpressBoxList = (params) => ajaxGet({ url: `fmall/express_box/normal_list/`, params });
export const ExpressBoxAdd = (params) => ajaxPost({ url: `fmall/express_box/`, params });
export const ExpressBoxEdit = (params) => ajaxPut({ url: `fmall/express_box/`, params });
export const ExpressBoxDelete = (params) => ajaxDelete({ url: `fmall/express_box/`, params });
export const ExpressBoxDisable = (params) => ajaxPut({ url: `fmall/express_box/disable/`, params });