<template>
	<div ref="orderPrint" class="print-template">
		<div v-for="item in data_list" style="font-size: 14px;padding-top: 20px;page-break-after: always;">
			<div style="margin: 8px 40%;">
				<span>客户订单汇总</span>
			</div>
			<div class="main-space" style="display: flex;">
				<div class="left" style="width: 60%;">
					<div style="display: flex;margin-top: 6px;">
						<!-- <span style="font-size:26px ;" >{{item.goods_no_area}}</span> -->
						<!-- <div style="width: 150px;height: 80px;line-height: 80px;font-size: 34px;font-weight: bold; border: 1px #000000 solid;margin-left: 8px;text-align: center; align-items: center;justify-content: center;">
						{{item.goods_no}}
					</div> -->

						<div
							style="width: 200px;height: 60px;line-height: 60px;font-size: 30px;font-weight: bold; border: 1px #000000 solid;margin-left: 2px;text-align: center; align-items: center;justify-content: center;">
							{{item.pos_no}}
						</div>
					</div>
					<div style="margin-top:8px ;">
						<span style="font-size: 18px;font-weight: bold;">{{item.address}}</span>
					</div>
					<div style="margin-top: 6px;">
						<span style="font-size: 14px;">{{item.address_detail}}</span>
					</div>

					<div style="margin-top: 8px;display: flex;">
						<span style="font-size: 18px;">{{item.address_name}} </span>
						<span style="font-size: 18px; margin-left: 8px;font-weight: bold;">{{item.address_phone}}</span>
					</div>

				</div>

				<div class="right" style="width: 40%;">
					<div style="margin-top: 40px;">
						<span style="font-size: 32px; font-weight: bold;">{{item.express_name}}</span>
					</div>
					<div style="margin-top: 10px;">
						<div>
							<span style="font-size: 24px;">{{item.date}}</span>
						</div>
						<div style="margin-top: 20px;">
							<span style="font-size: 18px; margin-top: 40px;">共{{item.total_product_count}}扎</span>
						</div>
						
						<div style="margin-top: 20px;">
							<span style="font-size: 18px; margin-top: 40px;">{{item.pickup_way_name}}</span>
						</div>

					</div>
				</div>
			</div>
			<div class="foot" style="margin-top: 20px;border-top:1px #000000 solid">
				<div style="margin-left: 65%; font-size: 16px;">
					<span>{{item.ji_hua_bao}} </span>
					<span>{{item.service_phone}} </span>

				</div>
			</div>
			<!-- <div style="display: flex;align-items: center;"> -->
			<!-- <img :src="formData.zyImg" style="width:40px;height: 40px;border-radius: 60%;object-fit:contain;" /> -->
			<!-- <div style="padding-left: 5px;"> -->
			<!-- <h6 style="font-size: 16px;">{{ formData.zyName }}</h6> -->
			<!-- <div style="font-size: 11px;padding-top: 5px;">{{ formData.date }}</div> -->
			<!-- </div> -->

			<!-- </div> -->
			<!-- <h1 style="font-size: 25px;padding-top: 10px;padding-bottom: 10px;">{{ formData.goods_no }}</h1> -->
			<!-- <div style="font-size: 20px;padding-bottom: 10px;">{{ formData.express_name }}</div> -->
			<!-- <div style="font-size: 11px;"> -->
			<!-- <span>{{ formData.address_name }}</span> -->
			<!-- <span style="padding-left: 5px;">{{ formData.phone }}</span> -->
			<!-- <span style="padding-left: 5px;">{{ formData.address_detail }}</span> -->
			<!-- </div> -->
			<!--   <div style="height: 260px;padding-top: 20px;font-size: 10px;">
            <span>{{ formData.goods_info }}</span>
        </div> -->
			<!-- <div style="font-size: 12px;">
            <div>总数：{{ formData.total_count }}扎</div> 
            <div style="padding-top: 3px;">客服：{{ formData.service_phone }}</div> -->
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	import print from '@/utils/print'
	import {
		loadCustomerOrdersPrintDataApi
	} from '@/api/print'
	import {
		fmallOrderPrintOrder
	} from '@/api/api'
	import {
		deepClone
	} from "@/utils/util"
	export default {
		name: "customerOrdersPrintMoudle",
		emits: ['closed'],
		data() {
			return {
				id_list: [],
				order_date: "",
				data_list: [],
				data_type: "",
				formData: {

					date: '2024-06-13',
					goods_no_area: "A区",
					goods_no: '109',
					ji_hua_bao: "集花宝分捡中心",
					service_phone: "18987573857",
					address_name: '赵钱孙李',
					address_phone: "18788556355",
					address: "云南省 昆明市 呈贡区 ",
					address_detail: "斗南集花宝",
					express_name: "和硕冷链"
				},
			}
		},
		methods: {
			handleClose() {
				this.$emit('closed')
			},
			handleOpen(item) {

				if (item) {
					let data = deepClone(item)
					this.id_list = data.id_list
					this.order_date = data.order_date
					this.data_type = data.data_type
				}

				// this.$nextTick(() => {
				//   this.handlePrint()
				// })
				// if (item) {
				//     this.formData = deepClone(item)
				// }
				this.getPrintData()
			},
			handlePrint() {
				print(this.$refs.orderPrint)
				// const PrintContent = this.$refs.orderPrint
				// const PrintWindow = window.open("", "_blank")
				// const htmlContent = `
				//     <html>
				//         <head>
				//             <title></title>
				//             <style>
				//             .print-template {
				//                 display: none;
				//                 /* 默认不显示 */
				//             }
				//             @media print {
				//                 .print-template {
				//                     display: block;
				//                     /* 打印时显示 */
				//                 }

				//                 /* 去除页眉和页脚 */
				//                 @page {
				//                     margin-top: 0;
				//                     margin-bottom: 0;
				//                 }
				//             }
				//             </style>
				//         </head>
				//         <body>
				//             ${PrintContent.innerHTML}
				//         </body>
				//     </html>
				// `
				// PrintWindow.document.write(htmlContent)
				// PrintWindow.document.close()
				// PrintWindow.onload = () => {
				//     PrintWindow.print()
				//     PrintWindow.close()
				// }
			},
			getPrintData() {

				loadCustomerOrdersPrintDataApi({
					data_type: this.data_type,
					id_list: JSON.stringify(this.id_list),
					order_date: this.order_date
				}).then(res => {
					if (res.code === 2000) {
						if (res.data) {
							this.data_list = res.data

							this.$nextTick(() => {
								this.handlePrint()
							})

							// res.data.forEach(item=>{
							// 	 this.formData = item

							// })
						}


					}
				})
			}
		}
	}
</script>
<style scoped>
	.print-template {
		display: none;
		/* 默认不显示 */
	}

	@media print {
		.print-template {
			display: block;
			/* 打印时显示 */
		}

		/* 去除页眉和页脚 */
		@page {
			margin-top: 0;
			margin-bottom: 0;
			margin-left: 20px;
			margin-right: 20px;
		}
	}
</style>