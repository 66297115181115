import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";

export const loadAppListApi = (params) =>ajaxGet({ url: `client/app/list`, params });

