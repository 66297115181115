<template>
	<div :class="{ 'ly-is-full': isFull }">
		<div class="tableSelect" ref="tableSelect">
			<el-form :inline="true" :model="formInline" label-position="left">
				<el-form-item label="归属系统：">
					<el-select v-model="formInline.appid" placeholder="请选择" style="width: 220px;" filterable >
						<el-option v-for="item in appList" :key="item.id" :label="item.app_name" :value="item.appid">
						</el-option>
					</el-select>
				</el-form-item>
				<el-button @click="search" type="primary" icon="Search">查询</el-button>
                <el-form-item label="" ><el-button  @click="handleClick('group')" type="warning" icon="FolderAdd" >新增配置分组</el-button></el-form-item>
                <el-form-item label="" ><el-button  @click="handleClick('item')" type="primary" icon="Plus" >新增配置项</el-button></el-form-item>
			</el-form>
		</div>
		<div class="sys-tip" >
			<p>当前查询数据归属系统：{{appName}}</p>
		</div>
		<div class="content" style="margin-top: 14px;">
			<el-tabs  type="card" v-model="editableTabsValue"   >
				<el-tab-pane v-for="(item, index) in tabList" :key="item.name" :label="item.title"
					:name="item.name" >
					<el-scrollbar :style="{height:scrowHight+'px'}">
					    <!-- <FormItem :options="item" :editableTabsItem="item"></FormItem> -->
					    <configMoudle :options="item" :editableTabsItem="item" :groupId="item.id" @refreshData="getData"></configMoudle>
					</el-scrollbar>
				</el-tab-pane>
			</el-tabs>

		</div>
         
		 
       <addGroup ref="addGroupRef" @refreshData="getData"></addGroup>
       <addItem ref="addItemRef" @refreshData="getData"></addItem>

		<!-- <add-role ref="addRoleFlag" @refreshData="search"></add-role> -->
	</div>
</template>
<script>
	// import addRole from "./components/addRole";
	import Pagination from "@/components/Pagination";
	 import FormItem from "./components/formItem";
	 import configMoudle from "./components/sysConfigModule";
	 
	 import addGroup from "./components/addGroup";
	 import addItem from "./components/addConfigItem";
	
	import {
		dateFormats,
		getTableHeight
	} from "@/utils/util";
	

	import {
		loadAppListApi
	} from '@/api/app_manage'
	
	import {loadConfigListApi,manageConfigStatusApi} from '@/api/platformconfig'

	export default {
		components: {
			Pagination,
			// addRole,
			FormItem,
			configMoudle,
			addGroup,
			addItem
		},
		name: 'roleManage',
		data() {
			return {
				scrowHight:500,
				isFull: false,
				tableHeight: 500,
				loadingPage: false,
				appList: [],
				formInline: {
					page: 1,
					limit: 10,
					search: '',
					name: '',
					status: '',
					appid: "1"
				},
				pageparm: {
					page: 1,
					limit: 10,
					total: 0
				},
				tableData: [],
                tabList:[],
				activeTabId:0,
				editableTabsValue: '2',
				editableTabs: [{
					title: 'Tab 1',
					name: '1',
					content: 'Tab 1 content'
				}, {
					title: 'Tab 2',
					name: '2',
					content: 'Tab 2 content'
				}],
				tabIndex: 2,
				appName:"",

			}
		},
		methods: {
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			setFull() {
				this.isFull = !this.isFull
			},
			addRole() {
				this.$refs.addRoleFlag.addRoleFn(null, '新增')
			},
			handleClick(flag){
				if(flag == 'group'){
					this.$refs.addGroupRef.handleOpen({appid:this.formInline.appid},"新增配置分组")
				    // addGroupRef.value.handleOpen('',"新增分组")
				}else if(flag == 'item'){
				    
					this.$refs.addItemRef.handleOpen({appid:this.formInline.appid},"新增配置项")
				        // addContentFlag.value.addModuleFn('',"新增配置项")
				    
				}
			},
			removeTab(index){
				console.log("tabname:",index)
				console.log("this.activeTabId:",this.activeTabId)
				// if(this.activeTabId>0){
				// 	this.manageStatus((this.activeTabId,'del'))
				// }
			},
			manageStatus(item_id,flag){
				let msg=""
				var that=this
				if(flag=="del"){
					msg="确定删除该配置组吗？请确定该配置组下的配置已对当前系统无影响"
				}
				
				that.$confirm(msg,"提示", {
					closeOnClickModal: false
				}).then(() => {
					manageConfigStatusApi({
						id: row.id,
						act_type:flag
					}).then(res => {
						if (res.code == 2000) {
							that.$message.success(res.msg)
							that.search()
						} else {
							that.$message.warning(res.msg)
						}
					})
				}).catch(() => {
				
				})
			},
			
			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				this.getData()
			},
			search() {
				this.formInline.page = 1
				this.formInline.limit = 20
				this.getData()
			},
			//获取列表
			async getData() {
				this.loadingPage=true
				loadConfigListApi({limit:999,parent_id:0,appid:this.formInline.appid}).then(res=>{
					
					this.loadingPage=false
				    if(res.code == 2000){
						this.tabList=res.data.data_list
						this.appName=res.data.app_name
						
				        // this.editableTabs.value = res.data
				    }
				})
			},
			getAppList() {
				loadAppListApi({
					status: 1,
					limit: 99
				}).then(res => {

					if (res.code == 2000) {
						console.log("res:", res)
						this.appList = res.data.data_list

					}
				})
			},
			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				this.tableHeight = getTableHeight(this.$refs.tableSelect.offsetHeight)
			}
		},
		created() {
			this.getAppList()
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			window.addEventListener('resize', this.listenResize);
			this.$nextTick(() => {
				this.getTheTableHeight()
			})
		},
		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>
<style lang="scss">
	.tableNav {
		overflow: hidden;
		margin-bottom: 20px;
	}
</style>