<template>
  <div ref="orderPrint" class="print-template">
    <div v-for="item in formData" style="font-size: 14px;padding-top: 20px;page-break-after: always;">
      <div style="display: flex;align-items: center;">
        <!-- <img :src="item.zyImg" style="width:40px;height: 40px;border-radius: 60%;object-fit:contain;" /> -->
        <div style="padding-left: 5px;">
          <!-- <h6 style="font-size: 16px;">{{ item.zyName }}</h6> -->
          <div style="font-size: 11px;padding-top: 5px;">{{ item.date }}</div>
        </div>

      </div>
      <h1 style="font-size: 25px;padding-top: 10px;padding-bottom: 10px;">{{ item.goods_no }}</h1>
      <div style="font-size: 20px;padding-top: 10px;padding-bottom: 10px;">{{ item.print_kuaidi }}</div>
      <div style="font-size: 11px;">
        <span>{{ item.address_name }}</span>
        <span style="padding-left: 5px;">{{ item.phone }}</span>
        <span style="padding-left: 5px;">{{ item.address }}</span>
      </div>
     <!-- <div style="height: 260px;padding-top: 20px;font-size: 10px;">
        <span>{{ item.goods_info }}</span>
      </div> -->
    <!--  <div style="font-size: 12px;">
        <div>总数：{{ item.total_count }}扎</div>
        <div style="padding-top: 3px;">客服：{{ item.kefu }}</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import print from '@/utils/print'
import {fmallOrderPrintOrder} from '@/api/api'
// import{loadAgentPrintListApi} from '@/api/print'

import {deepClone} from "@/utils/util"

export default {
  name: "modulePrintOrderMultiple",
  emits: ['closed'],
  data() {
    return {
      ids: [],
	  id_list:[],
	  order_date:"",
      formData: [{
        id: "",
        zyImg: '',
        zyName: '',
        date: '',
        goods_no: '',
        goods_info: '',
        total_count: 0,
        kefu: ""
      }],
    }
  },
  methods: {
    handleClose() {
      this.ids = []
      this.$emit('closed')
    },
    handleOpen(item) {
		console.log("open_--",item)
      if (item) {
        let data = deepClone(item)
		this.id_list=data.id_list
		this.order_date=data.order_date
      }
      this.getPrintData()
    },
    handlePrint() {
      print(this.$refs.orderPrint)
      // const PrintContent = this.$refs.orderPrint
      // const PrintWindow = window.open("", "_blank")
      // const htmlContent = `
      //     <html>
      //         <head>
      //             <title></title>
      //             <style>
      //             .print-template {
      //                 display: none;
      //                 /* 默认不显示 */
      //             }
      //             @media print {
      //                 .print-template {
      //                     display: block;
      //                     /* 打印时显示 */
      //                 }

      //                 /* 去除页眉和页脚 */
      //                 @page {
      //                     margin-top: 0;
      //                     margin-bottom: 0;
      //                 }
      //             }
      //             </style>
      //         </head>
      //         <body>
      //             ${PrintContent.innerHTML}
      //         </body>
      //     </html>
      // `
      // PrintWindow.document.write(htmlContent)
      // PrintWindow.document.close()
      // PrintWindow.onload = () => {
      //     PrintWindow.print()
      //     PrintWindow.close()
      // }
    },
    getPrintData() {
      // const ids = [];
      // const agent_ids = [];
      // this.ids.forEach(item => {
      //   // 如果是数字那么push到ids，如果是字符串截取“agent_”后面的内容到agent_ids
      //   if (typeof item === 'number') {
      //     ids.push(item);
      //   } else {
      //     agent_ids.push(item.split('_')[1]);
      //   }
      // });
      // loadAgentPrintListApi({id_list:JSON.stringify(this.id_list),order_date:this.order_date}).then(res => {
      //   if (res.code === 2000) {
      //     this.formData = res.data
      //     this.$nextTick(() => {
      //       this.handlePrint()
      //     })
      //   }
      // })
    },
  }
}
</script>
<style scoped>
.print-template {
  display: none;
  /* 默认不显示 */
}

@media print {
  .print-template {
    display: block;
    /* 打印时显示 */
  }

  /* 去除页眉和页脚 */
  @page {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>

