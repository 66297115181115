<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="90%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    商品信息
                </div>
                <el-table border :data="formData.product_list">
                    <el-table-column width="80" prop="order_type_name" label="类型"></el-table-column>
                    <el-table-column min-width="130" prop="product_name" label="商品名称"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column width="80" prop="product_cover" label="图片">
                        <template #default="scope">
                            <el-image :src="scope.row.product_cover" style="width: 30px;height: 30px"
                                :preview-src-list="[scope.row.product_cover]" :preview-teleported="true"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110"  label="商家(编码)">
						<template #default="scope">
						   <p>{{scope.row.shop_name}}({{scope.row.shop_code}})</p>
						</template>
					</el-table-column>
                    <el-table-column width="110" prop="spec_value" label="商品规格"></el-table-column>
                    <el-table-column width="80" prop="level" label="等级"></el-table-column>
                    <el-table-column width="100" prop="count" label="商品数量"></el-table-column>
                    <el-table-column width="100" prop="spec_weight" label="商品重量"></el-table-column>
                    <!-- <el-table-column min-width="110" prop="price" label="成本价" v-if="identity != 4"> -->
                    <el-table-column min-width="110" prop="price" label="成本价" >
                        <template #default="scope">
                            {{ scope.row.price }}
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="add_price" label="商品加价" >
						<template #default="scope">
						    {{ scope.row.add_price+scope.row.area_add_price }}
						</template>
					</el-table-column>
                    <el-table-column min-width="110" prop="sale_price" label="商品售价"></el-table-column>
                    <el-table-column min-width="110" prop="username" label="总重量">
                        <template #default="scope">
                            {{ ((parseFloat(scope.row.spec_weight)) * scope.row.count).toFixed(1)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="total_product_money" label="总价"></el-table-column>
					<el-table-column min-width="110" prop="checkin_status_name" label="入位情况" >
					</el-table-column>
					<el-table-column min-width="110" prop="checkin_type_name" label="入位类型" >
						
					</el-table-column>
					<el-table-column min-width="110" prop="real_count" label="入位数量" >
						
					</el-table-column>
					<el-table-column min-width="110" prop="lock_product_money" label="缺货金额" >
						
					</el-table-column>
					
                </el-table>
                <div class="ly-title" style="margin-top: 10px;">
                    订单信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="5">
                        <el-form-item label="订单编号：">
                            {{ formData.order_id }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="下单时间：">
                            {{ formData.create_datetime }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="订单状态：">
                            {{ formData.status_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="支付时间：">
                            {{
                                formData.pay_time
                            }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="支付方式：">
                            {{
                                formData.pay_way
                            }}
                        </el-form-item>
                    </el-col>
					 <el-col :span="5">
                        <el-form-item label="支付单号：">
                            {{
                                formData.pay_order_no
                            }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="运费：">
                            {{ formData.postage }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="订单金额：">
                            {{ formData.total_orders_money }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="买家留言：">
                            {{ formData.remark }}
                        </el-form-item>
                    </el-col>
                   <el-col :span="5">
                        <el-form-item label="优惠金额：">
                            {{ formData.discount_value }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="打包费：">
                            {{ formData.packaging_fee }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="实付金额：">
                            {{ formData.real_pay_money }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="收货时间：">
                            {{ formData.delivery_time }}
                        </el-form-item>
                    </el-col>
                   <!-- <el-col :span="6">
                        <el-form-item label="代理名称：">
                            {{ formData.agent_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="parseFloat(formData.agent_fee) > 0">
                        <el-form-item label="代理提成：">
                            {{ formData.agent_fee }}
                        </el-form-item>
                    </el-col> -->
                </el-row>
				<el-row :gutter="20">
					<el-col :span="5">
						<el-form-item label="收货方式:">
							{{formData.pickup_way_text}}
						</el-form-item>
					</el-col>
				</el-row>
                <div class="ly-title"
                    v-if="!!formData.payinfo.user_refund_info && JSON.stringify(formData.payinfo.user_refund_info) != '{}'">
                    <span>用户退款信息</span>
                </div>
                <el-row :gutter="20"
                    v-if="!!formData.payinfo.user_refund_info && JSON.stringify(formData.payinfo.user_refund_info) != '{}'">
                    <el-col :span="8">
                        <el-form-item label="退款类型：">
                            {{ formData.payinfo.user_refund_info.type }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款时间：">
                            {{ formData.payinfo.user_refund_info.pay_time }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款金额：">
                            {{ formData.payinfo.user_refund_info.amount }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    <span>地址信息</span><span v-if="!!formData.pdcode">（拼单点）</span>
                </div>
                <el-form-item label="联系人：">
                    {{ formData.address_name }}
                </el-form-item>
                <el-form-item label="联系电话：">
                    {{ formData.address_phone }}
                </el-form-item>
                <el-form-item label="详细地址：">
                    {{ formData.address_detail }}
                </el-form-item>
                <div class="ly-title">
                    物流信息
                </div>
                <el-row :gutter="20" v-if="formData.logistics_id">
                    <el-col :span="6">
                        <el-form-item label="物流公司：">
                            {{ formData.logistics_company }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="物流单号：">
                            {{ formData.logistics_id }}<el-button style="margin-left: 14px;" type="primary" size="small"
                                @click="getLogisticsSearch" :loading="loadingLogistics">查看物流</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="物流费用：">
                            {{ formData.logistics_fee }}
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="包装费用：">
                            {{ formData.logistics_pkg_fee }}
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <div class="ly-title">
                    评价
                </div>
                <div v-for="itemc in formData.commentinfo" v-if="!!formData.commentinfo && formData.commentinfo.length > 0">
                    <el-form-item label="评分：">
                        <el-rate v-model="itemc.score" disabled />
                    </el-form-item>
                    <el-form-item label="图片：">
                        <el-image v-for="item in itemc.imgs" preview-teleported :src="item" :preview-src-list="[item]"
                            style="width: 100px;height: 100px;margin-right: 10px;"></el-image>
                    </el-form-item>
                    <el-form-item label="评语：">
                        <el-input v-model="formData.content" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                            :disabled="true"></el-input>
                    </el-form-item>
                </div>

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderDetail, orderLogisticsSearch,getOrdersDetailApi } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { getStorage } from "@/utils/util"
export default {
    name: "orderDetailModule",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingLogistics: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: "",
			act_type:'',
            formData: {
                payinfo: {
                    payinfo: {},
                    user_refund_info: {},
                    user_freight_refund_info: {},
                    user_lackgoods_refund_info: {},
                    user_freight_pay_info: {},
                },
                commentinfo: [],
                goods_info: [],
                logistics_id: "",
                logistics_company: "",
                logistics_company_code: "",
				
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
            identity: getStorage('identity')
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag,act_type) {
            this.loadingTitle = flag
            this.dialogVisible = true
			this.act_type=act_type
            if (item) {
				if(act_type=="agent"){
					 this.id = item.agent_id
				}
				if (act_type=="normal"){
					if(item.parent_id){
						this.id = item.parent_id
					}else{
						this.id = item.id
					}
					 
				}
               
                this.getOderDetail(this.id)
            }
        },
        getLogisticsSearch() {
            this.loadingLogistics = true
            orderLogisticsSearch({ logistics_company_code: this.formData.logistics_company_code, logistics_id: this.formData.logistics_id, show: "2" }).then(res => {
                this.loadingLogistics = false
                const PrintWindow = window.open("", "_blank")
                const htmlContent = `
                <html>
                    <head>
                        <title>物流信息</title>
                        <style>
                        
                        </style>
                    </head>
                    <body>
                        ${res}
                    </body>
                </html>
            `
                PrintWindow.document.write(htmlContent)
                PrintWindow.document.close()
            })
        },
        getOderDetail(id) {
            getOrdersDetailApi({ id: id,act_type:this.act_type }).then(res => {
				
                if (res.code === 2000) {
                    this.formData = res.data
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

