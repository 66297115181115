<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="归属系统：">
                	<el-select v-model="formData.appid" placeholder="请选择" style="width: 220px;" filterable>
                		<el-option v-for="item in appList" :key="item.id" :label="item.app_name" :value="item.appid">
                		</el-option>
                	</el-select>
                </el-form-item>
				<el-form-item label="分组名称：" prop="title">
                    <el-input v-model="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="key值：" prop="key">
                    <el-input v-model="formData.key"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {platformsettingsSysconfigAdd,platformsettingsSysconfigEdit} from "@/api/api";
    import {addGroupApi} from "@/api/platformconfig";
    import LyDialog from "@/components/dialog/dialog";
	import {
		loadAppListApi
	} from '@/api/app_manage'
	
    export default {
        name: "addModuleGroup",
        components: {LyDialog},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
					appid:"1",
                    title:'',
                    key:'',
                },
				appList:[],
                rules:{
					appid:[
						{required: true, message: '请请传入appid',trigger: 'blur'}
					],
                    title: [
                        {required: true, message: '请输入分组名称',trigger: 'blur'}
                    ],
                    key: [
                        {required: true, message: '请输入key值',trigger: 'blur'}
                    ],
                },
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    title:'',
                    key:'',
                }
                this.$emit('refreshData')
            },
            handleOpen(item,flag) {
				this.getAppList()
                this.loadingTitle=flag
                this.dialogVisible=true
				this.formData.appid=item.appid
                if(item){
                    this.formData = item
                }
            },
			getAppList() {
				loadAppListApi({
					status: 1,
					limit: 99
				}).then(res => {
			
					if (res.code == 2000) {
						this.appList = res.data.data_list
			
					}
				})
			},
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            platformsettingsSysconfigEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            addGroupApi(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            }
        }
    }
</script>
<style scoped>

</style>