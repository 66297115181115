<template>
  <div :class="{'ly-is-full':isFull}">
	   <div>
	   	<el-tabs v-model="activeName" type="card" @tab-click="changeTab">
			<el-tab-pane label="邀请码" name="inviteCode"></el-tab-pane>
	   		<el-tab-pane label="密码修改" name="pwd"></el-tab-pane>
	   		
	   	</el-tabs>
	   </div>
	   <div v-if="activeName==='pwd'">
		   <el-form ref="userPasswordForm" :model="formData"
		      center label-width="120px" style="margin: 50px auto">
		     <el-form-item label="原密码：" required >
		       <el-input size="large" v-model="formData.old_password" clearable style="width: 360px"></el-input>
		     </el-form-item>
		     <el-form-item required  label="新密码：">
		       <el-input size="large" type="password" v-model="formData.new_password" clearable
		         style="width: 360px"></el-input>
		     </el-form-item>
		     <el-form-item required  label="确认密码：">
		       <el-input size="large" type="password" v-model="formData.new_password_2" clearable
		         style="width: 360px"></el-input>
		     </el-form-item>
		     <el-form-item>
		       <el-button type="primary" @click="settingPassword" v-show="hasPermission(this.$route.name, 'Changepassword')">
		         <i class="fa fa-check"></i>提交
		       </el-button>
		       <el-button @click="resetForm('passwordForm')" type="info"
		         v-show="hasPermission(this.$route.name, 'Changepassword')">
		         <i class="fa fa-reply-all"></i>重置
		       </el-button>
		     </el-form-item>
		   </el-form>
	   </div>
	   <div v-if="activeName=='inviteCode'">
		   <div>
			   <p style="margin-left:20px;margin-top: 15px;">大客户系统邀请码，生成后10分钟内有效，一个邀请码只能一人注册使用</p>
		   </div>
		   <el-form ref="inviteForm" :model="inviteForm" center label-width="120px" style="margin: 50px auto">
		     <el-form-item label="邀请码："  >
		       <el-input size="large" v-model="textToCopy"  style="width: 360px" readonly></el-input>
			   <el-button  @click="copyText">复制</el-button>
			   <p v-if="copied">复制成功！</p>
		     </el-form-item>
		     <el-form-item   label="过期时间：">
		       <el-input size="large"  v-model="inviteForm.expire_time" style="width: 360px" readonly></el-input>
		     </el-form-item>
		    
		    
		     <el-form-item>
		       <el-button type="primary" @click="loadInviteCode" v-show="hasPermission(this.$route.name, 'Changepassword')">
		         <i class="fa fa-check"></i>获取
		       </el-button>
		       
		     </el-form-item>
		   </el-form>
	   </div>
	  
	
  </div>
    

</template>

<script>

import { ref } from 'vue';
import { useClipboard } from '@vueuse/core'
import {updateAdminOwnPwsApi,loadInviteCodeApi } from '@/api/system'
import { useMutitabsStore } from "@/store/mutitabs";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
  name: "personalCenter",
  components: {
    LyUploadAvatar,
  },
  setup() {
    const mutitabsstore = useMutitabsStore()
	
	const textToCopy = ref("");
	    const { copy, copied  } = useClipboard({
			source:textToCopy
		});
	const copyText = async () => {
	      try {
	        await copy(textToCopy.value); // 调用copy函数并传递要复制的文本
			
	      } catch (e) {
	        console.error('复制失败', e);
	      }
	    };
	
    return { mutitabsstore,copyText,textToCopy ,copied }
  },
  data() {
	  
	  return{
		  isFull: false,
		  activeName:"inviteCode",
		  formData:{
			  'old_password':"",
			  "new_password":"",
			  "new_password_2":"",
		  },
		  inviteForm:{
			  code:"",
			  expire_time:"",
		  }
	  }
    // var validatePass = (rule, value, callback) => {
    //   const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else if (value === this.userPasswordInfo.oldPassword) {
    //     callback(new Error('原密码与新密码一致'))
    //   }
    //   // else if (!pwdRegex.test(value)) {
    //   //   callback(new Error('您的密码复杂度太低(密码中必须包含字母、数字)'))
    //   // }
    //   else {
    //     if (this.userPasswordInfo.newPassword2 !== '') {
    //       this.$refs.userPasswordForm.validateField('newPassword2')
    //     }
    //     callback()
    //   }
    // }
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'))
    //   } else if (value !== this.userPasswordInfo.newPassword) {
    //     callback(new Error('两次输入密码不一致!'))
    //   } else {
    //     callback()
    //   }
    // }
    // return {
    //   position: 'right',
    //   activeName: 'passwrod',
    //   userInfo: {
    //     name: '',
    //     gender: 1,
    //     mobile: '',
    //     avatar: '',
    //     email: '',
    //     identity: 1,
    //   },
    //   userInforules: {
    //     // avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
    //     name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
    //     mobile: [
    //       { required: true, pattern: /^1[3|4|5|7|8|6]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
    //     ],
    //     bank_name: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
    //     bank_no: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
    //   },
    //   userPasswordInfo: {
    //     oldPassword: '',
    //     newPassword: '',
    //     newPassword2: ''
    //   },
    //   passwordRules: {
    //     oldPassword: [
    //       {
    //         required: true,
    //         message: '请输入原密码',
    //         trigger: 'blur'
    //       }
    //     ],
    //     newPassword: [{ validator: validatePass, trigger: 'blur' }],
    //     newPassword2: [{ validator: validatePass2, trigger: 'blur' }]
    //   }
    // }
  },
  mounted() {
  
  },
  methods: {
     changeTab(tab,event){
		 
	 },
  
     setFull() {
     	this.isFull = !this.isFull
     	window.dispatchEvent(new Event('resize'))
     },
    // 重置
    resetForm(name) {
      const _self = this
      if (name === 'info') {
        _self.getCurrentUserInfo()
      } else {
        _self.userPasswordInfo = {
          oldPassword: '',
          newPassword: '',
          newPassword2: ''
        }
      }
    },
    // tab切换,默认切换清除原字符
    handleClick(tab, event) {
      const _self = this
      // if (tab.paneName === 'userInfo') {
      //   _self.$refs.userPasswordForm.resetFields()
      // } else {
      //   _self.$refs.userInfoForm.resetFields()
      // }
    },
	loadInviteCode(){
	   	loadInviteCodeApi().then(res=>{
			console.log(res)
			if(res.code===2000){
				this.inviteForm.code=res.data.code
				this.textToCopy=res.data.code
				this.inviteForm.expire_time=res.data.expire_time
			}else{
				this.$message.error(res.msg)
			}
		})
	},
    /**
     * 重新设置密码
     */
    settingPassword() {
      const that = this
      if(this.formData.new_password!=this.formData.new_password_2){
		   this.$message.warning("两次密码不一样")
		  return
	  }
	  if(!this.formData.new_password){
		   this.$message.warning("请输入新密码")
		  return
	  } 
	  if(!this.formData.new_password_2){
		   this.$message.warning("请输入确认密码")
		  return
	  }
      updateAdminOwnPwsApi({"old_password":this.formData.old_password,"new_password":this.formData.new_password,"new_password_2":this.formData.new_password_2}).then(res => {
        if (res.code == 2000) {
          // _self.activeName = 'userInfo'
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
	// copyCode(){
	//    if(this.inviteForm.code){
	// 	   this.$copyText(this.inviteForm.code).then(()=>{
	// 		    this.$message.success("复制成功")
	// 	   },
	// 	   ()=>{
	// 		    this.$message.warning("复制失败")
	// 	   }
	// 	   )
		   
	//    }else{
	// 	   this.$message.warning("请先获取邀请码")
	//    }
	// }

  }
}
</script>

<style scoped>
.ly-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  font-size: 17px;
  padding-bottom: 15px;
  color: #3c4a54;
  font-weight: bold;
}

.el-tabs .el-tabs__content {
  background-color: var(--el-bg-color) !important;
}

.el-tabs {
  background-color: var(--el-bg-color) !important;
}

::v-deep(.el-tabs__header) {
  margin-top: 20px !important;
}
</style>
