<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
				 
                <el-form-item label="名称或编码：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="商家名称或编码或珍情编码"
                        @change="search" style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.contact_mobile" maxlength="60" clearable placeholder="手机号"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                    <el-input v-model.trim="formInline.areaname" maxlength="60" clearable placeholder="地址" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="审核：">
                    <el-select v-model="formInline.audit_status" placeholder="请选择" clearable style="width: 90px"
                        @change="search">
                        <el-option v-for="item in auditList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable style="width: 90px" @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> 
				
				<el-form-item label="商家来源：">
                    <el-select v-model="formInline.shop_from" placeholder="请选择" clearable style="width: 150px" @change="search">
                        <el-option v-for="item in shop_from_type_list" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
             <!--   <el-form-item label="时间：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" v-loading="loadingPage" ref="tableref"
                style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange">
				 <el-table-column  width="120" align="center" label="序号" prop="id"></el-table-column>
                <!-- <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column width="100" prop="shop_name" label="店铺名称" show-overflow-tooltip=""></el-table-column>
                <el-table-column width="120" prop="shop_code" label="店铺编码"></el-table-column>
                <el-table-column width="100" prop="shop_type_name" label="店铺分类" ></el-table-column>
                <el-table-column width="100" prop="category_name" label="供应品种" show-overflow-tooltip="">
					
				</el-table-column>
                <el-table-column width="90" prop="contact_name" label="联系人"></el-table-column>
                <el-table-column width="100" prop="contact_phone" label="联系电话" show-overflow-tooltip=""></el-table-column>
                <el-table-column width="100" prop="address_detail" label="地址" show-overflow-tooltip=""></el-table-column>
                <el-table-column width="100" prop="station_name" label="集货站" show-overflow-tooltip=""></el-table-column>
                <el-table-column width="100"  label="优质商家" show-overflow-tooltip="">
					<template #default="scope">
						<el-tag v-if="scope.row.is_excellent" @click="setTag(scope.row,'excellent')" style="color: red;">是</el-tag>
						<el-tag v-if="!scope.row.is_excellent" @click="setTag(scope.row,'excellent')">否</el-tag>
					</template>
				</el-table-column>
                <el-table-column v-if="formInline.shop_from=='2'" width="100" prop="zq_area_name" label="珍情商家基地" show-overflow-tooltip=""></el-table-column>
                <el-table-column v-if="formInline.shop_from=='2'" width="120" prop="zq_shop_id" label="珍情商家id" show-overflow-tooltip=""></el-table-column>
					
                <!-- <el-table-column width="170" prop="create_datetime" label="创建时间"></el-table-column> -->
                <el-table-column width="100" prop="audit_status_name" label="审核状态">
                    <!-- <template #default="scope">
                        <el-tag type="success" v-if="scope.row.audit_status == 20">
                            {{ auditList.filter(item => item.id == scope.row.audit_status)[0].name }}
                        </el-tag>
                        <el-tag type="danger" v-else-if="scope.row.audit_status == 30">
                            {{ auditList.filter(item => item.id == scope.row.audit_status)[0].name }}
                        </el-tag>
                        <el-tag type="warning" v-else>
                            {{ auditList.filter(item => item.id == scope.row.audit_status)[0].name }}
                        </el-tag>
                    </template> -->
                </el-table-column>
                <el-table-column width="90" prop="audit_remarks" label="审核备注" show-overflow-tooltip></el-table-column>
                <el-table-column width="100" label="状态">
                    <template #default="scope">
						<el-button v-if="scope.row.status" type="primary" round size="small" @click="mangeStatus(scope.row.id,'stop')">使用中</el-button>
						<el-button v-if="!scope.row.status" type="warning" round size="small" @click="mangeStatus(scope.row.id,'start')">未使用</el-button>
                        <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch> -->
                    </template>
                </el-table-column>
                <el-table-column label="操作" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
                            v-show="hasPermission(this.$route.name, 'Retrieve')">查看</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'update')"
                            >修改</span>
                        <span v-if="scope.row.audit_status==10" class="table-operate-btn" @click="handleEdit(scope.row, 'audit')"
                            v-show="hasPermission(this.$route.name, 'Audit')">审核</span>
                        <span class="table-operate-btn" @click="mangeStatus(scope.row.id, 'del')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
							
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-module ref="addModuleFlag" v-if="isDialogVisible" @closed="isDialogVisible = false"></add-module>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats } from "@/utils/util";
import { fmallShop, fmallShopDelete, fmallShopDisable } from '@/api/api'
import { loadShopListApi,manageShopStatusApi,manageShopTagApi } from '@/api/shop'
import AddModule from "./components/addModuleShop";
import { lyMixins } from "@/mixins/mixins"
export default {
    mixins: [lyMixins],
    components: {
        AddModule,
        Pagination,
    },
    name: 'shopManage',
    data() {
        return {
            isFull: false,
            loadingPage: false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            defaultImg: require('../../assets/img/avatar.jpg'),
            formInline: {
                page: 1,
                limit: 10,
				shop_from:1,
				name:''
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            auditList: [
                { id: 10, name: '审核中' },
                { id: 20, name: '审核通过' },
                { id: 30, name: '审核未通过' },
            ],
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			shop_from_type_list:[
				{ id: 1, name: '平台商家' },
				{ id: 2, name: '珍情商家' },
			]
        }
    },
    methods: {
		setTag(data,tag_type){
		  if(tag_type=="excellent"){
			  let msg=""
			  let act_type=""
			  if (data.is_excellent){
				  msg="确定取消该商家优质标签吗？"
				  act_type="cancel_excellent"
			  }
			  if (!data.is_excellent){
				  msg="确定给该商家打上优质标签吗？"
				   act_type="set_excellent"
			  }
			  var that=this
			  this.$confirm(msg,"提示",{
				  closeOnClickModal: false,
				  type: 'warning'
			  }).then(res=>{
				  manageShopTagApi({id:data.id,act_type:act_type}).then(res=>{
					  if(res.code==2000){
						  that.$message({message:res.msg,type:'success'})
						  that.getData()
					  }else{
						  that.$message(res.msg)
					  }
				  })
			  })
			  
		  }	
		},
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        changeStatus(row) {
            let vm = this
            let flat = row.status
            row.status = !row.status
            vm.$confirm('确定修改状态吗？', '提醒', {
                closeOnClickModal: false,
                type: 'warning'
            }).then(res => {
                fmallShopDisable({ id: row.id }).then(res => {
                    if (res.code == 2000) {
                        flat ? row.status = true : row.status = false
                        vm.$message.success(res.msg)
                        vm.getData()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        //多选项框被选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 批量删除按钮操作 */
        handleDelete(row) {
            const ids = this.ids;
            let vm = this
            vm.$confirm('是否确认删除选中的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return fmallShopDelete({ id: ids }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.search()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            })
        },
        // addAdmin() {
        //     this.$refs.addGoodClassModuleFlag.addGoodClassModuleFn(null,'新增')
        // },
        handleEdit(row, flag) {
            if (flag == 'detail') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleFlag.handleOpen(row, "查看", false)
                })
            } if (flag == 'audit') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleFlag.handleOpen(row, "审核", true)
                })
            }
			if (flag == 'update') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleFlag.handleOpen(row, "修改", false)
                })
            }
            // else if (flag == 'delete') {
            //     let vm = this
            //     vm.$confirm('您确定要删除选中的分类？', {
            //         closeOnClickModal: false
            //     }).then(res => {
            //         fmallShopDelete({ id: row.id }).then(res => {
            //             if (res.code == 2000) {
            //                 vm.$message.success(res.msg)
            //                 vm.search()
            //             } else {
            //                 vm.$message.warning(res.msg)
            //             }
            //         })
            //     }).catch(() => {

            //     })
            // }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.getData()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadShopListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
					console.log("res:",res.data)
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
		mangeStatus(id,act){
			let msg=""
			if(act=="stop"){
				msg="确定停用该商家吗？停用后该商家将不能发布商品等操作"
			}
			if(act=="start"){
				msg="确定启用该商家吗？"
			}
			if(act=="del"){
				msg="确定删除该商家吗？删除后商家将不能进行登录等操作，请谨慎进行该操作"
			}
			    let that = this
			    that.$confirm(msg, "提示",{
					confitmButtonText:'确定',
					cancelButtonText:'取消'
				}).then(res => {
					
					if(res=="confirm"){
						manageShopStatusApi({
							id:id,
							act:act
						}).then(res=>{
							console.log("success:",res)
							if(res.code==2000){
								this.getData()
							}else{
								this.$message.warning(res.msg)
							}
						})
					}
			        
			    }).catch(() => {
			
			    })
			
			
		}
    },
    created() {
        this.getData()
    },
    timers(val) {
        if (val) {
            this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
            this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
        } else {
            this.formInline.beginAt = ''
            this.formInline.endAt = ''
        }
        this.getData()
    },
}
</script>
