import axios from "axios";
import {
  ajaxGet,
  ajaxPost,

} from "./request";
import { url } from "./url";


export const updateAgentSendTypeApi = (params) =>ajaxPost({ url: `agent/update/sendtype`, params });
//打包费设置
export const loadPackagingListApi = (params) =>ajaxGet({ url: `agent/packaging/fee/list`, params });

export const addPackagingFeeApi = (params) =>ajaxPost({ url: `agent/packaging/fee/add`, params });
export const editPackagingFeeApi = (params) =>ajaxPost({ url: `agent/packaging/fee/edit`, params });
export const managePackagingFeeApi = (params) =>ajaxPost({ url: `agent/packaging/fee/manage/status`, params });
  