<template>
    <div>
        <ly-dialog v-model="is_show_package" :title="loadingTitle" width="560px" @closed="handleClose">
			
			<div v-if="action=='weight'">
				<el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
				    label-width="auto"> 
					
					 <el-form-item label="实际总重量：" prop="real_weight" class="is-required">
					     <el-input-number v-model="formData.real_weight" @change="handleChange" :controls="false"
					         style="width: 100%;" placeholder="请输入订单实际总重量"></el-input-number>
					 </el-form-item>
				    <!-- <el-form-item label="包装规格：" prop="real_weight" class="is-required">
				        <el-select  placeholder="请选择" @change="handleChange" filterable
				                   clearable style="width: 100%;">
				          <el-option v-for="item in box_list" :key="item.id" :label="item.name" :value="item.id">
				          </el-option>
				        </el-select>
				    </el-form-item> -->
				    
				 </el-form>
			</div>
            <!-- <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto"> -->
				<!-- <el-form-item v-for="item in box_list" :key="item.id" :label="item.name">
					<div style="display:flex">
						<p>使用量：</p>
						<el-input>{{item.count}}</el-input>
					</div>
					
				</el-form-item> -->
                <!-- <el-form-item label="包装规格：" prop="real_weight" class="is-required">
                    <el-select  placeholder="请选择" @change="handleChange" filterable
                               clearable style="width: 100%;">
                      <el-option v-for="item in box_list" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                </el-form-item> -->
                
            <!-- </el-form> -->
			<div v-if="action=='package'">
				<el-table :height="450" border default-expand-all row-key="order_id"
					:data="box_list" ref="packageTable" >
					
					<el-table-column min-width="180" prop="name" label="规格"></el-table-column>
					<el-table-column min-width="180" prop="count" label="使用数量">
						<template #default="scope">
						    <el-input-number  placeholder="使用数量,没使用则不填"  :min="0" :controls="false" v-model="scope.row.count"
						        :max="9999" style="width:100%" ></el-input-number>
						    
						</template>
						
						
					</el-table-column>
					
					</el-table>
			</div>
			
			
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderYunfeibk,setOrderPackagesApi,setOrderWeightApi } from "@/api/api";
import { loadExpressBoxListApi } from "@/api/express";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { ElInputNumber } from "element-plus";
export default {
    name: "addPackageModule",
    components: { LyDialog, ElInputNumber },
    emits: ['refreshData', 'closed'],
    data() {
        return {
			action:'',
            is_show_package: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                id: 0,
                order_id:"",
				address_name:"",
				agent_name:"",
				real_weight:0,
				act_type:"",
				order_date:''
            },
            rules: {
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
            },
			box_list:[],
			
        }
    },

    methods: {
		getData(){
			loadExpressBoxListApi({page:1,limit:99,status:1}).then(res=>{
			
				let box_list=[]
				if(res.code==2000){
					
					if (res.data.data_list){
						res.data.data_list.forEach(item=>{
							box_list.push({
								"id":item.id,
								"name":item.name,
								"count":0
							})
						})
						
					}
					
				}
				this.box_list=box_list
				console.log("box_list:",this.box_list)
			})
		},
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item,action,act_type,order_date) {
			this.getData()
			this.action=action
		
            if (item) {
                let tempData = deepClone(item)
				
				
				this.formData.order_date=order_date
				this.formData.act_type=act_type
				this.formData.id=tempData.id
				
				// if(act_type=="agent"){
				// 	this.formData.id=tempData.agent_id
				// }
				// if(act_type=="normal"){
					
				// }
                // this.formData.id = tempData.id
                // this.formData.order_id = tempData.id
                // this.formData.address_name = parseFloat(tempData.address_name)
                // this.formData.agent_name = tempData.agent_name
                
            }
			
			if (action=="package"){
				 this.loadingTitle = "打包(订单归属日期："+this.formData.order_date+")"
			}else{
				 this.loadingTitle = "称重(订单归属日期："+this.formData.order_date+")"
			}
			
			this.is_show_package = true
			           
        },
        handleChange(val) {
			// console.log("change:",val)
            // if (val > this.formData.weight) {
            //     this.formData.freight_type_name = "补运费"
            //     this.formData.freight_type = 1
            // } else if (val < this.formData.weight) {
            //     this.formData.freight_type_name = "退运费"
            //     this.formData.freight_type = 2
            // } else {
            //     this.formData.freight_type_name = ""
            //     this.formData.freight_type = 0
            // }
            // this.getYunfeiNewFreight()
        },
        getYunfeiNewFreight() {
            let params = { id: this.formData.id, orderfreight: 1, real_weight: this.formData.real_weight }
            fmallOrderYunfeibk(params).then(res => {
                if (res.code === 2000) {
                    this.formData.freight_amount = res.data
                }
            })
        },
		submitWeight(){
			if (this.formData.real_weight<=0){
				 this.$message.warning("请填写实际重量")
				
				return
			}
			setOrderWeightApi({
				id:this.formData.id,
				real_weight:this.formData.real_weight,
				act_type:this.formData.act_type,
				
			}).then(res => {
			    this.loadingSave = false
			    if (res.code == 2000) {
			        this.$message.success(res.msg)
			        this.is_show_package = false;
			        this.handleClose()
			        this.$emit('refreshData')
			    } else {
			        this.$message.warning(res.msg)
			    }
			})
		},
		submitPackage(){
			let use_box_id_list=[]
			this.box_list.forEach(item=>{
				if(item.count>0){
					use_box_id_list.push({id:item.id,count:item.count,name:item.name})
				}
			})
			
			if(use_box_id_list.length<1){
				this.$msg('请填写包装盒子数量')
				return 
			}
			
			this.loadingSave = true
			let param={
				id:this.formData.id,
				box_ids:use_box_id_list,
				act_type:this.formData.act_type
			}
			setOrderPackagesApi(param).then(res => {
			    this.loadingSave = false
			    if (res.code == 2000) {
			        this.$message.success(res.msg)
			        this.is_show_package = false;
			        this.handleClose()
			        this.$emit('refreshData')
			    } else {
			        this.$message.warning(res.msg)
			    }
			})
		},
        submitData() {
			
			if(this.formData.id<1){
			
				this.$message.warning('请重新选择订单')
				return 
			}
			if(this.action=="package"){
				this.submitPackage()
			}
			if(this.action=="weight"){
				this.submitWeight()
			}
			
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

