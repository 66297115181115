<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
				<el-form-item label="优惠券类型：" prop="discount_type">
				   <el-radio-group v-model="formData.discount_type">
				   	<el-radio :value="1" :disabled="formData.status">满减券</el-radio>
				   	<!-- <el-radio :value="2" disabled=true>折扣券（暂不可使用）</el-radio> -->
				   </el-radio-group>
				</el-form-item>
				
                <el-form-item label="优惠券名称：" prop="name">
                    <el-input v-model.trim="formData.name" placeholder="请输入优惠券名称" :disabled="formData.status" style="width: 50%"></el-input>
                </el-form-item>
				
                <el-form-item label="新人券：" prop="is_new_customer" class="is-required">
                    <el-radio-group v-model="formData.is_new_customer">
                    	<el-radio :value="0" :disabled="formData.status">否（登录即可领取）</el-radio>
                    	<el-radio :value="1" :disabled="formData.status">是（新人券，注册即送）</el-radio>
                    </el-radio-group>
                </el-form-item> 
				
				<el-form-item label="优惠金额：" prop="discount_value" >
                    <el-input v-model="formData.discount_value" style="width: 30%;" :controls="false"
                        placeholder="优惠金额(单元:元,达到以下使用条件即可使用)" :disabled="formData.status"></el-input>
                </el-form-item>
				
				<el-form-item label="限领张数/人：" prop="per_customer_count" >
                    <el-input v-model="formData.per_customer_count" style="width: 30%;" :controls="false"
                        :disabled="formData.status"></el-input>
                </el-form-item>
				
				<el-form-item label="使用门槛:" prop="formData.use_limit_type" class="is-required">
					
					<el-radio-group v-model="formData.use_limit_type" :disabled="formData.status">
						<el-radio :value="1">最低商品数量</el-radio>
						<el-radio :value="2">最低商品总金额</el-radio>
					</el-radio-group>
				</el-form-item>
                <el-form-item v-if="formData.use_limit_type==1"  prop="min_product_count">
					<div style="display: flex;margin-left: 18%;">
						<label>订单内商品最低数量：</label>
						<el-input-number v-model="formData.min_product_count" :min="1" style="width: 30%;"
						    placeholder="1" :disabled="formData.status"></el-input-number>
					</div>
                    
                </el-form-item>
				<el-form-item v-if="formData.use_limit_type==2"  prop="min_product_money">
					<div style="display: flex;margin-left: 18%;">
						<label>订单内商品最低总金额（单位：元）：</label>
						<el-input-number v-model="formData.min_product_money" :min="1" style="width: 30%;" :controls="false"
						    placeholder="1" :disabled="formData.status" ></el-input-number>
					</div>

                </el-form-item>
				
				<el-form-item label="发放数量：" prop="total_count">
                    <el-input-number v-model="formData.total_count" :min="0" style="width: 30%;" :controls="false"
                        placeholder="发放数量"></el-input-number>
                </el-form-item>
                <el-form-item label="领取时间：" class="is-required">
                    <div style="display: flex;">
                        <el-date-picker v-model="formData.get_begin_time" type="date" placeholder="开始日期"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%;"  />
                        <div style="width: 100px;text-align: center;">至</div>
                        <el-date-picker v-model="formData.get_end_time" type="date" placeholder="结束日期" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD" style="width: 100%;" />
                        <div style="width: 100px;text-align: right;">可领</div>
                    </div>
                </el-form-item>
				<el-form-item label="使用时间:" prop="formData.date_type" class="is-required">
					
					<el-radio-group v-model="formData.date_type" :disabled="formData.status">
						<el-radio :value="0">指定使用时段</el-radio>
						<el-radio :value="1">领取后几天内有效</el-radio>
					</el-radio-group>
				</el-form-item>
				
				<el-form-item v-if="formData.date_type==0"  class="is-required" >
                    <div style="display: flex;margin-left: 18%;">
                        <el-date-picker v-model="formData.use_begin_time" type="date" placeholder="开始日期"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%;" :disabled="formData.status" />
                        <div style="width: 100px;text-align: center;">至</div>
                        <el-date-picker v-model="formData.use_end_time" type="date" placeholder="结束日期" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD" style="width: 100%;" :disabled="formData.status" />
                        <div style="width: 100px;text-align: right;">可用</div>
                    </div>
                </el-form-item>
				<el-form-item v-if="formData.date_type==1"  class="is-required" >
                    <div style="display: flex;margin-left: 18%;">
						<div>领取后</div>
                       <el-input-number v-model="formData.days" :min="1" style="width: 30%;" :controls="false"
                           ></el-input-number>
                        <div style="width: 100px;text-align: right;">天之内可用</div>
                    </div>
                </el-form-item>
                <el-form-item label="使用说明：" prop="desc">
                    <el-input v-model="formData.desc" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                        placeholder="请输入" :disabled="formData.status"></el-input>
                </el-form-item>
				<el-form-item label="是否向所有用户显示:" prop="formData.date_type" class="is-required">
					<el-radio-group v-model="formData.is_publish" :disabled="formData.status">
						<el-radio :value="true">启用后所有用户可以看到</el-radio>
						<el-radio :value="false">仅后台可看,派发后用户可看</el-radio>
					</el-radio-group>
				</el-form-item>
				
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" style="width: 100%;"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallGoodsCouponAdd, fmallGoodsCouponEdit } from "@/api/api";
import { addCouponApi,editCouponApi } from "@/api/coupon";

import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog },
    emits: ['refreshData'],
    name: "addModuleGoodsCoupon",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
				total_count:0,
				is_new_customer:0,
				discount_type:1,
				discount_value:0,
				use_limit_type:2,
				min_product_money:0,
				min_product_count:0,
				get_begin_time:'',
				get_end_time:'',
				use_begin_time:'',
				use_end_time:'',
				desc:'',
				sort:0,
				status:false,
				per_customer_count:1,
				date_type:0,
				days:1,
				is_publish:false,
				
            },
			use_limit_type_list:[{id:1,name:'最低商品数量'},{id:2,name:'最低商品金额'}],
            rules: {
                name: [
                    { required: true, message: '请填写优惠券名称', trigger: 'blur' }
                ],
                discount_value: [
                    { required: true, message: '请填写优惠金额', trigger: 'blur' }
                ],
                total_count: [
                    { required: true, message: '请填写发放数量', trigger: 'blur' }
                ],
				
				get_begin_time: [
                    { required: true, message: '请填写优惠券开始领取时间', trigger: 'blur' }
                ],
				get_end_time: [
                    { required: true, message: '请填写优惠券截止领取时间', trigger: 'blur' }
                ],
				use_begin_time: [
                    { required: true, message: '请填写优惠券开始使用时间', trigger: 'blur' }
                ],
				use_end_time: [
                    { required: true, message: '请填写优惠券截止使用时间', trigger: 'blur' }
                ],
				per_customer_count: [
                    { required: true, message: '请填写每人限领张数', trigger: 'blur' }
                ],
				is_publish: [
                    { required: true, message: '请选显示类型', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                name: '',
                total_count:0,
                is_new_customer:0,
                discount_type:1,
                discount_value:0,
                use_limit_type:2,
                min_product_money:0,
                min_product_count:0,
                get_begin_time:'',
                get_end_time:'',
                use_begin_time:'',
                use_end_time:'',
                desc:'',
                status:false
            }
        },
        addModuleFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
				console.log("edit:",this.formData)
				if(item.is_new){
					this.formData.is_new_customer=1
				}else{
					this.formData.is_new_customer=0
				}
				
            }
        },
        submitData() {
			if(this.formData.status){
				this.$message("优惠券正在发放，不可编辑！")
				return
			}
			
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        editCouponApi(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
								 this.handleClose()
                                this.$message.success(res.msg)
                               
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        addCouponApi(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                
                                this.$emit('refreshData')
								this.handleClose()
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

