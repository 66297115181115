import axios from "axios";
import {

  ajaxGet,
  ajaxPost,
  
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
// export const loadBannerListApi = (params={}) =>ajaxGet({url:'banner/list', params})
export const loadPackaginFeeListApi = (params={}) =>ajaxPost({url:'mall/orders/load/packaging/fee', params})
export const updateOrdersPackagingFeeApi = (params={}) =>ajaxPost({url:'mall/orders/update/pickup-way', params})

