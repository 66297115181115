<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="收货人：">
                                <el-input style="width:100%" v-model.trim="formInline.address_name" maxlength="60" clearable
                                    placeholder="收货人名称" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
						<el-col :span="6">
                            <el-form-item label="代理名称：">
                                <el-input style="width:100%" v-model.trim="formInline.agent_name" maxlength="60" clearable
                                    placeholder="代理人姓名相关" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
						<el-col :span="6">
                            <el-form-item label="订单编号：">
                                <el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
                                    placeholder="订单编号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
						<el-col :span="6">
                            <el-form-item label="订单来源：">
								<el-select v-model="formInline.appid" placeholder="请选择" style="width: 220px;" filterable >
									<el-option v-for="item in appList" :key="item.id" :label="item.app_name" :value="item.appid">
									</el-option>
								</el-select>
                                
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单日期：">
								
									<el-date-picker  v-model="time_frame" type="daterange"
									    unlink-panels 
										:shortcuts="shortcuts" @change="changeDate"
									     range-separator="至" start-placeholder="开始日期"
									    end-placeholder="结束日期" align="right" >
									</el-date-picker>
								 
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
								<el-button v-show="hasPermission(this.$route.name, 'printOrder')"
									@click="exportOrder()" type="primary" icon="Receiving"
									>导出成交数据
								</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
					<el-row style="margin-top: 12px;" >
						    <el-form-item label="统计时间段：">
								<el-input style="width: 120px;height: 30px;" v-model="start_time"  readonly
								     ></el-input>-
								<el-input style="width:120px" v-model="end_time"  
								    @change="search"></el-input>
							</el-form-item>
							
							<el-form-item label="订单总数：" >
							    <el-input style="width:80px" v-model="total_orders_count"  readonly
							         ></el-input>
							</el-form-item>
					
							<el-form-item label="成交总扎数：">
							    <el-input style="width:80px" v-model="total_product_count"  readonly
							         ></el-input>
								
							</el-form-item>
							<el-form-item label="成交总金额(实付)：">
							    <el-input style="width:100px" v-model="total_orders_real_money"  readonly
							         ></el-input>
								
							</el-form-item>
							<el-form-item label="总计分润：">
							    <el-input style="width:100px" v-model.trim="total_cost"  readonly ></el-input>
							</el-form-item>
							<el-form-item label="总计退款：">
							    <el-input style="width:100px" v-model.trim="platform_total_refund"  readonly    ></el-input>
								
							</el-form-item>
							<el-form-item label="总计毛利：">
							    <el-input style="width:100px" v-model.trim="total_profit"  readonly
							         ></el-input>
								
							</el-form-item>
						
					</el-row>
                </div>
            </el-form>
        </div>
        <div class="table">
          <el-table :height="tableHeight-100" border :data="dataList" ref="tableref" v-loading="loadingPage">
            <el-table-column  width="60" align="center" label="序号" >
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column>
            <el-table-column width="150" prop="order_time" label="下单时间" show-overflow-tooltip></el-table-column>
            <el-table-column width="120" prop="address_name" label="收货人"></el-table-column>
            <el-table-column width="150" prop="order_id" label="订单号" show-overflow-tooltip></el-table-column>
            <el-table-column width="120" prop="pickup_way_text" label="订单类型"></el-table-column>
            <el-table-column width="150" prop="agent_name" label="代理" show-overflow-tooltip></el-table-column>
            <el-table-column width="110" prop="app_name" label="订单来源"></el-table-column>
            <el-table-column width="120" prop="total_product_count" label="订单数量"></el-table-column>
            <el-table-column width="120" prop="checkin_count" label="入位数量"></el-table-column>
            <el-table-column width="120" prop="real_orders_money" label="实付金额"></el-table-column>
            <el-table-column width="120" prop="agent_cost" label="代理成本"></el-table-column>
            <el-table-column width="120" prop="shop_cost" label="商家成本"></el-table-column>
            <el-table-column width="150" prop="platform_refund" label="售后退款(平台付)"></el-table-column>
            <el-table-column width="120" prop="profit" label="毛利"></el-table-column>
            
          </el-table>
		  
        </div>
		 <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { loadAppListApi } from '@/api/app_manage'

import { loadOrdersStatisticApi,exportOrdersStatiscApi } from '@/api/finance'
import {getExportOrdersStatisticUrl} from '@/api/export'
import {setStorage,getStorage} from '@/utils/util'
import axios from "axios";
import { ElLoading } from 'element-plus'

export default {
    components: {
        Pagination,
    },
    name: 'mallOrderStatic',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
				appid:"1",
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            
            time_frame: "",
			start_time:"",
			end_time:"",
			total_orders_count:0,
			total_product_count:0,
			total_orders_money:0,
			total_orders_real_money:0,
			platform_total_refund:0,
			total_cost:0,
			total_profit:0,
            dataList: [],
			value2:"",
			appList:[],
			
			shortcuts: [{
				            text: '上一周',
				            value:()=> {
				              const end = new Date();
				              const start = new Date();
							  
							  const now = new Date();
							      const nowDayOfWeek = now.getDay(); // 获取今天是星期几，0是周日，6是周六
							      const lastWeekDay = nowDayOfWeek === 0 ? 6 : nowDayOfWeek - 1; // 上一周的同一天是星期几
							      const lastWeekMonday = new Date(now);
							      lastWeekMonday.setDate(now.getDate() - lastWeekDay - 7); // 上周一的日期
							      const lastWeekSunday = new Date(lastWeekMonday);
							      lastWeekSunday.setDate(lastWeekMonday.getDate() + 6); // 上周日的日期

							  
							  
							  
				              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							  
				              return [lastWeekMonday, lastWeekSunday];
				            }
				          }, {
				            text: '上一个月',
				             value:()=> {
				              let today = new Date();
				                  let year = today.getFullYear();
				                  let month = today.getMonth();
				                  let prevMonth = month - 1;
				              
				                  // 如果是一月份，年份减一，月份变为12
				                  if (prevMonth === -1) {
				                      prevMonth = 11;
				                      year--;
				                  }
				              
				                  // 获取上个月的最后一天
				                  let lastDayOfPrevMonth = new Date(year, prevMonth + 1, 0).getDate();
				              
				                  // 上个月的第一天
				                  let firstDayOfPrevMonth = new Date(year, prevMonth, 1);
				                  // 上个月的最后一天
				                  let lastDayOfPrevMonthDate = new Date(year, prevMonth, lastDayOfPrevMonth);
				               return [firstDayOfPrevMonth, lastDayOfPrevMonthDate];
				            }
				          }, {
				            text: '最近三个月',
				             value:()=> {
				             let today = new Date();
				                 let year = today.getFullYear();
				                 let month = today.getMonth();
				             
				                 // 上上个月的月份
				                 let twoMonthsAgoMonth = month - 3;
				             
				                 // 如果当前月份减去2小于0，说明是年初的月份，年份减1，月份变为11（去年的12月）
				                 if (twoMonthsAgoMonth < 0) {
				                     twoMonthsAgoMonth += 12;
				                     year--;
				                 }
				             
				                 // 上上个月的第一天
				                 let firstDayOfTwoMonthsAgo = new Date(year, twoMonthsAgoMonth, 1);
				                 // 上上个月的最后一天
				                 let lastDayOfTwoMonthsAgo = new Date(year, twoMonthsAgoMonth + 3, 0);
				               return [firstDayOfTwoMonthsAgo, lastDayOfTwoMonthsAgo];
				            }
				          }]
			
        }
    },

    methods: {
		getApp(){
		  loadAppListApi({status:1}).then(res=>{
			  if(res.code===2000){
				  this.appList=res.data.data_list
			  }
		  })	
		},
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
		callFather(parm) {
		    this.formInline.page = parm.page
		    this.formInline.limit = parm.limit
		    this.getData()
		},
		changeDate(e){
		  	
		   let start_time=e[0].toLocaleDateString()
		   let end_time=e[1].toLocaleDateString()
		   start_time=start_time.replace("/","-")
		   start_time=start_time.replace("/","-")
		   end_time=end_time.replace("/","-")
		   end_time=end_time.replace("/","-")
		   this.formInline.start_time=start_time
		   this.formInline.end_time=end_time
		},
		getToday(){
			 const now = new Date();
			 this.formInline.start_time=now.toLocaleDateString()
			 this.formInline.end_time=now.toLocaleDateString()
			 this.formInline.start_time=this.formInline.start_time.replace("/","-")
			 this.formInline.start_time=this.formInline.start_time.replace("/","-")
			 this.formInline.end_time=this.formInline.end_time.replace("/","-")
			 this.formInline.end_time=this.formInline.end_time.replace("/","-")
			 return [now.toLocaleDateString(),now.toLocaleDateString()]
		}, 
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        handleEdit(row, flag) {
            if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
			
			// if(this.time_frame){
			// 	let start_time=this.time_frame[0].toLocaleDateString()
			// 	let end_time=this.time_frame[1].toLocaleDateString()
			// 	start_time=start_time.replace("/","-")
			// 	start_time=start_time.replace("/","-")
			// 	end_time=end_time.replace("/","-")
			// 	end_time=end_time.replace("/","-")
			// 	this.formInline.start_time=start_time
			// 	this.formInline.end_time=end_time
			// }
            loadOrdersStatisticApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
					
                    this.dataList = res.data.data_list
                    this.total_cost=res.data.total_cost
					this.total_orders_count=res.data.total_orders_count
					this.total_product_count=res.data.total_product_count
					this.total_profit=res.data.total_profit
					this.start_time=res.data.start_time
					this.end_time=res.data.end_time
					this.total_orders_real_money=res.data.total_orders_real_money
					this.platform_total_refund=res.data.platform_total_refund
                    this.pageparm.total = res.data.total;
                }else{
					this.$message.error(res.msg)
				}
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight) + 50
        },
		async exportOrder(){
			 // console.log("url:",getExportOrdersPressUrl())
			 if(!this.formInline.start_time || !this.formInline.end_time){
				 this.$message.warning("请选择统计时段")
				 return
			 }
			 let loading=ElLoading.service({lock:true,text:"导出中，请稍等..."})
			 let token=getStorage('logintoken')
			const response=await axios({
				url:getExportOrdersStatisticUrl(),
				headers:{
				    Authorization: 'JWT ' + token,
				},
				method:"GET",
				params:{start_time:this.formInline.start_time,end_time:this.formInline.end_time,appid:this.formInline.appid,address_name:this.formInline.address_name,agent_name:this.formInline.agent_name,order_id:this.formInline.order_id},
				responseType:'blob',
			})
			
			const url=window.URL.createObjectURL(new Blob([response.data]));
			const link=document.createElement('a');
			link.href=url;
			link.setAttribute('download',this.formInline.start_time+"到"+this.formInline.end_time+"_成交统计分析.xls")
			document.body.append(link);
			link.click()
			loading.close()
			this.$message("请检查下载进度")
			// exprotOrderExpressApi({order_date:this.formInline.order_date}).then(res=>{
				
			// })
		},

    },
    created() {
		this.getApp()
        
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
<style scoped>
.lystaticCard:deep(.el-card) .el-card__header {
    padding: 10px;
}
</style>
